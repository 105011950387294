$intermediate: "(min-aspect-ratio: 1 / 1) and (max-aspect-ratio: 1440 / 1080)"

.contacts-section
  position: relative
  width: 100%
  height: 100vh
  z-index: 1
  @media #{$tablet}
    height: auto
  &__sticky
    position: sticky
    top: 0
    width: calc(vh(100) * (16 / 9))
    max-width: 100%
    height: vh(100)
    margin: 0 auto
    padding: dpx(174) dpx(55) dpx(180)
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: space-between
    @media #{$tablet}
      min-height: 100vh
      height: auto
      width: tpx(450)
      padding: tpx(86) 0 tpx(74)
      justify-content: center
    @media #{$mobile}
      padding: mpx(86) mpx(20) mpx(74)
      width: 100%
      justify-content: flex-start
  &__title 
    font-size: dpx(80)
    font-weight: 400
    coor: $dark
    opacity: 0
    transform: translateY(50px)
    transition-duration: 350ms
    transition-timing-function: $easeInOut
    transition-property: transform, opacity
    transition-delay: 0ms
    @at-root .contacts-section--visible &
      transform: translateY(0)
      opacity: 1
    @media #{$intermediate}
      font-size: dpx(64)
    @media #{$tablet}
      font-size: tpx(40)
      font-weight: 500
      padding-bottom: tpx(38)
    @media #{$mobile}
      font-size: mpx(40)
      font-weight: 500
      padding-bottom: mpx(38)
  &__list
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    gap: dpx(50)
    @media #{$intermediate}
      flex-wrap: wrap
    @media #{$tablet}
      gap: 0
      flex-direction: column
    @media #{$mobile}
      gap: 0
      flex-direction: column
    .contact-elem
      flex: 0 1 dpx(570)
      padding: dpx(20) 0 dpx(50)
      height: dpx(346)
      border-top: dpx(2) solid $dark
      border-bottom: dpx(6) solid $dark
      display: flex
      flex-direction: column
      justify-content: space-between
      opacity: 0
      transform: translateY(50px)
      transition-duration: 350ms
      transition-timing-function: $easeInOut
      transition-property: transform, opacity
      @at-root .contacts-section--visible &
        transform: translateY(0)
        opacity: 1
      @media #{$intermediate}
        flex: 0 1 calc(100% / 2 - dpx(50))
        padding: dpx(20) 0 
        height: auto
      @media #{$tablet}
        padding: tpx(20) 0
        height: tpx(150)
        width: 100%
        flex: none !important
        border-top: tpx(2) solid $dark
        border-bottom: none
      @media #{$mobile}
        padding: mpx(20) 0
        height: mpx(150)
        width: 100%
        flex: none !important
        border-top: mpx(2) solid $dark
        border-bottom: none 
      &:nth-child(1)
        transition-delay: 100ms
        @media #{$intermediate}
          flex: 1 1 100%
          a 
            margin-top: dpx(50)
      &:nth-child(2)
        transition-delay: 200ms
      &:nth-child(3)
        transition-delay: 300ms
        @media #{$tablet}
          border-bottom: tpx(6) solid $dark
        @media #{$mobile}
          border-bottom: mpx(6) solid $dark
      &__title
        font-size: dpx(20)
        font-weight: 500
        color: $dark
        text-transform: uppercase
        @media #{$tablet}
          font-size: tpx(15)
        @media #{$mobile}
          font-size: mpx(15)
      &__links 
        display: flex
        flex-direction: column
        gap: dpx(48)
        @media #{$tablet}
          gap: mpx(10)
        @media #{$mobile}
          gap: mpx(10)
        a
          display: block
          font-size: dpx(40)
          font-weight: 500
          color: $dark
          text-decoration: underline
          opacity: 1
          transition: opacity 150ms $easeInOut
          white-space: pre-line
          @media #{$intermediate}
            font-size: dpx(32)
          @media #{$tablet}
            font-size: tpx(24)
          @media #{$mobile}
            font-size: mpx(24)
          &:hover 
            opacity: 0.5
          &:active
            opacity: 1
