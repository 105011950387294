$intermediate-larger: "(min-aspect-ratio: 1200/1080) and (max-aspect-ratio: 1440/1080)"
$intermediate-smaller: "(min-aspect-ratio: 1/1) and (max-aspect-ratio: 1280/1080)"
$intermediate: "(min-aspect-ratio: 1/1) and (max-aspect-ratio: 1440/1080)"

.types-section
  width: 100%
  &__sticky
    position: sticky
    top: 0
    max-width: 100%
    margin: 0 auto
    height: vh(100)
    display: flex
    align-items: center
    justify-content: center
    width: calc(100% - dpx(110)) 
    @media #{$tablet}
      margin: 0 auto
      width: tpx(450)
    @media #{$mobile}
      width: 100%
      padding: mpx(50) mpx(20) mpx(50) mpx(20)
  &__list
    --count: 0
    display: flex
    align-items: center
    justify-content: center
    gap: dpx(10) 
    width: 100%
    @media #{$tablet}
      flex-direction: column
      gap: tpx(5)
    @media #{$mobile}
      flex-direction: column
      gap: mpx(5)
  &__item
    --y: 0
    --index: 0
    position: relative
    display: flex
    width: dpx(260)
    height: dpx(680)
    border-radius: dpx(10)
    background-color: $gray3
    cursor: pointer
    overflow: hidden
    transform: translateY(calc(var(--y) * 1px))
    transition-property: background-color, width, height, translate
    transition-duration: 400ms
    transition-timing-function: $easeInOut
    transition-delay: 0ms, 0ms, 0ms, calc((var(--index) - 1) * 50ms)
    z-index: 1
    @media #{$tablet}
      transform: translateY(0)
      width: 100% 
      height: tpx(90)
      border-radius: tpx(10)
      translate: 0 0 !important
    @media #{$mobile}
      height: mpx(85)
      border-radius: mpx(10)
    &--idle
      translate: 0 0
    &--in
      translate: 0 calc(var(--index) * 100px)
    &--out
      translate: 0 calc(var(--index) * -100px)
      transition-delay: 0ms, 0ms, 0ms, calc((var(--count) - var(--index) - 1) * 50ms)
    &--active
      width: dpx(680)
      background-color: transparent
      @media #{$tablet}
        background-color: $gray3
        width: 100%
    &--small
      width: dpx(176)
      @media #{$tablet}
        width: 100%
    &__corner
      position: absolute
      width: dpx(30)
      height: dpx(30)
      pointer-events: none
      opacity: 0
      transition-property: opacity, transform
      transition-duration: 300ms
      transition-timing-function: $easeInOut
      @media #{$tablet}
        width: tpx(15)
        height: tpx(15)
      @media #{$mobile}
        width: mpx(15)
        height: mpx(15)
      @at-root .types-section__item--active &
        transform: rotate(var(--angle))
        transition-delay: 400ms
        opacity: 1
        @media #{$tablet}
          transform: unset
          opacity: 0
      &::before,
      &::after
        content: ''
        position: absolute
        left: 0
        top: 0
        width: 100%
        height: 2px
        background-color: $dark
      &::after
        width: 2px
        height: 100%
      &--tr
        --angle: 90deg
        right: dpx(30)
        top: dpx(30)
        transform: translate(dpx(-15), dpx(15)) rotate(var(--angle))
        @media #{$tablet}
          right: tpx(20)
          top: tpx(20)
          transform: translate(tpx(-10), tpx(10)) rotate(var(--angle))
        @media #{$mobile}
          right: mpx(20)
          top: mpx(20)
          transform: translate(mpx(-10), mpx(10)) rotate(var(--angle))
      &--br
        --angle: 180deg
        right: dpx(30)
        bottom: dpx(30)
        transform: translate(dpx(-15), dpx(-15)) rotate(var(--angle))
        @media #{$tablet}
          right: tpx(20)
          bottom: tpx(20)
          transform: translate(tpx(-10), tpx(-10)) rotate(var(--angle))
        @media #{$mobile}
          right: mpx(20)
          bottom: mpx(20)
          transform: translate(mpx(-10), mpx(-10)) rotate(var(--angle))
      &--bl
        --angle: 270deg
        left: dpx(30)
        bottom: dpx(30)
        transform: translate(dpx(15), dpx(-15)) rotate(var(--angle))
        @media #{$tablet}
          left: tpx(20)
          bottom: tpx(20)
          transform: translate(tpx(10), tpx(-10)) rotate(var(--angle))
        @media #{$mobile}
          left: mpx(20)
          bottom: mpx(20)
          transform: translate(mpx(10), mpx(-10)) rotate(var(--angle))
    &__number,
    &__name
      position: absolute
      left: dpx(30)
      top: dpx(30)
      font-size: dpx(40)
      font-weight: 700
      z-index: 2
      white-space: nowrap
      opacity: 1
      transition-property: opacity, transform
      transition-duration: 400ms
      transition-timing-function: $easeInOut
      @media #{$intermediate-larger}
        font-size: dpx(32)
      @media #{$intermediate-smaller}
        font-size: dpx(28)
      @media #{$tablet}
        top: 50%
        left: tpx(20)
        transform: translateY(-50%)
        font-size: tpx(20)
        transition-delay: 400ms
        white-space: unset
      @media #{$mobile}
        left: mpx(20)
        font-size: mpx(20)
        transition-delay: 400ms
    &__number
      transform: translateX(0)
      @media #{$tablet}
        display: none
      @at-root .types-section__item--active &
        opacity: 0
        transform: translateX(dpx(-100))
    &__name
      opacity: 0
      transform: translateX(dpx(100))
      @media #{$tablet}
        opacity: 1
        transform: translateY(-50%) !important
        width: 50%
      @at-root .types-section__item--active &
        opacity: 1
        transform: translateX(0)
    &__preview
      @include noSelect
      position: absolute
      left: 0
      bottom: 0
      width: auto
      height: 100%
      object-fit: contain
      display: block
      filter: grayscale(1)
      z-index: 1
      transition-delay: 0ms, 300ms
      transition-property: filter, opacity
      transition-duration: 250ms, 300ms
      transition-timing-function: $easeInOut
      @media #{$tablet}
        display: none
      @media #{$mobile}
        display: none
      &--mobile
        display: none
        @media #{$tablet}
          display: block
          height: tpx(90)
          left: auto 
          right: 0
          top: 0
          filter: grayscale(0)
        @media #{$mobile}
          display: block
          height: mpx(90)
          left: auto 
          right: 0
          top: 0
          filter: grayscale(0)
      @at-root .types-section__item:hover &
        filter: grayscale(0)
      @at-root .types-section__item--active &
        transition-delay: 0ms
        opacity: 0
        @media #{$tablet}
          opacity: 1
    &__image
      @include fitImage
      position: absolute
      left: 0
      bottom: 0
      transform: scale(0.9)
      opacity: 0
      z-index: 1
      transition-property: transform, opacity
      transition-duration: 300ms
      transition-timing-function: $easeInOut
      @at-root .types-section__item--active &
        transition-delay: 250ms
        transform: scale(1)
        opacity: 1
        @media #{$tablet}
          opacity: 0
    &__link
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: 100%
      z-index: 3
      pointer-events: none
      @media #{$tablet}
        pointer-events: unset
      @at-root .types-section__item--active &
        pointer-events: unset
