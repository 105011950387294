$intermediate-larger: "(min-aspect-ratio: 1440 / 1080) and (max-aspect-ratio: 1700 / 1080)"
$intermediate-smaller: "(min-aspect-ratio: 1 / 1) and (max-aspect-ratio: 1440 / 1080)"
$intermediate: "(min-aspect-ratio: 1 / 1) and (max-aspect-ratio: 1700 / 1080)"

.news-section
  --groups-index: 0
  --groups-count: 1
  --index: 0
  --count: 1
  width: 100%
  height: calc(var(--groups-count) * 200vh)
  @media #{$intermediate}
    height: calc(var(--count) * 200vh)
  @media #{$tablet}
    overflow: hidden
    height: unset
  &__sticky
    position: sticky
    top: 0
    width: calc(vh(100) * (16 / 9))
    max-width: 100%
    height: vh(100)
    margin: 0 auto
    display: flex
    align-items: flex-start
    justify-content: space-between
    padding: 0 dpx(55)
    gap: dpx(50)
    overflow: hidden
    @media #{$intermediate}
      padding: 0 dpx(55)
      gap: 0
      min-height: vh(100)
    @media #{$tablet}
      overflow: unset
      width: tpx(450)
      min-height: 100vh
      height: auto
      padding: tpx(86) 0
      gap: 0
      flex-direction: column
      justify-content: flex-start
    @media #{$mobile}
      width: 100%
      min-height: 100vh
      padding: mpx(75) mpx(20) mpx(50) mpx(20)
  &__title
    position: relative
    overflow: hidden
    position: sticky
    left: 0
    top: 0
    padding: dpx(245) 0 dpx(735) 0
    display: flex
    align-items: center
    justify-content: flex-start
    flex: none
    gap: dpx(40)
    z-index: 2
    @media #{$tablet}
      margin: 0 auto
      width: tpx(450)
      top: 0
      padding: 0 0 tpx(100) 0
      gap: tpx(10)
      position: relative
    @media #{$mobile}
      margin: 0
      top: 0
      padding: 0 0 mpx(132) 0
      gap: mpx(10)
      position: relative
      width: 100%
    h2
      font-size: dpx(80)
      position: relative
      font-weight: 400
      @media #{$intermediate-smaller}
        font-size: dpx(64)
      @media #{$tablet}
        font-size: tpx(40)
        font-weight: 500
      @media #{$mobile}
        font-size: mpx(40)
        font-weight: 500
    span
      font-size: dpx(80)
      font-weight: 700
      color: $gray5
      @media #{$intermediate-smaller}
        font-size: dpx(64)
      @media #{$tablet}
        font-size: tpx(40)
      @media #{$mobile}
        font-size: mpx(40)
  &__list
    width: 100%
    height: 100%
    z-index: 1
    touch-action: auto !important
    &--desktop
      display: flex
      margin-left: auto
      position: absolute
      left: 0
      top: 0
      padding: inherit
      @media #{$tablet}
        display: none
      .swiper-wrapper
        display: flex
        flex-direction: row
        flex-wrap: wrap
        justify-content: flex-end
      .swiper-slide
        width: dpx(570)
        height: vh(100)
        display: flex
        justify-content: flex-end
        align-items: center
        gap: dpx(50)
        margin-left: dpx(50)
        @media #{$intermediate}
          width: dpx(670)
        @media #{$intermediate-larger}
          width: dpx(470)
        @media #{$tablet}
          margin-left: 0
          width: 100%
    &--mobile
      display: none
      @media #{$tablet}
        display: flex
    a
      position: relative
    .swiper
      width: 100%
      overflow: unset
  &__item
    --color: #{$dark}
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: center
    width: dpx(570)
    @media #{$intermediate}
      width: 100%
    @media #{$tablet}
      width: 100%
      height: auto
    &__date
      display: flex
      align-items: center
      justify-content: flex-start
      gap: dpx(20)
      font-size: dpx(20)
      font-weight: 600
      color: var(--color)
      @media #{$tablet}
        gap: tpx(10)
        font-size: tpx(15)
        font-weight: 500
      @media #{$mobile}
        gap: mpx(10)
        font-size: mpx(15)
        font-weight: 500
      &::before
        content: ''
        position: relative
        width: dpx(14)
        height: dpx(14)
        border-radius: 50%
        background-color: $gray5
        transition-property: background-color, opacity
        transition-duration: 250ms 
        transition-timing-function: $easeInOut
        @media #{$tablet}
          width: tpx(12)
          height: tpx(12)
        @media #{$mobile}
          width: mpx(12)
          height: mpx(12)
        @at-root .news-section__item:hover &
          background-color: $dark
    &__preview
      @include fillImage
      height: dpx(320)
      border-radius: dpx(10)
      margin: dpx(20) 0 dpx(40) 0
      filter: grayscale(1)
      transition: filter 250ms $easeInOut
      background-color: $gray5
      @media #{$intermediate}
        @include defaultImageRatio
      @media #{$tablet}
        @include defaultImageRatio
        margin: tpx(10) 0 tpx(20) 0
        border-radius: tpx(10)
        filter: grayscale(0)
      @media #{$mobile}
        @include defaultImageRatio
        margin: mpx(10) 0 mpx(20) 0
        border-radius: mpx(10)
        filter: grayscale(0)
      @at-root .news-section__item:hover &
        filter: grayscale(0)
    &__text
      height: dpx(144)
      font-size: dpx(40)
      font-weight: 600
      display: -webkit-box
      -webkit-box-orient: vertical
      -webkit-line-clamp: 3
      overflow: hidden
      word-break: break-word
      color: #858587
      opacity: 1
      transition-property: color, opacity
      transition-duration: 250ms 
      transition-timing-function: $easeInOut
      @media #{$intermediate-larger}
        font-size: dpx(32)
      @media #{$intermediate-smaller}
        height: auto
        font-size: dpx(32)
      @media #{$tablet}
        -webkit-line-clamp: 2
        height: auto
        font-size: tpx(20)
        font-weight: 500
        color: $dark
      @media #{$mobile}
        height: auto
        font-size: mpx(20)
        font-weight: 500
        color: $dark
      @at-root .news-section__item:hover &
        color: var(--color)
    &__border
      display: block
      width: 100%
      border-bottom: dpx(1) solid var(--color)
      padding-bottom: dpx(40)
      @media #{$tablet}
        border-bottom: tpx(2) solid var(--color)
        padding-bottom: tpx(20)
      @media #{$mobile}
        border-bottom: mpx(2) solid var(--color)
        padding-bottom: mpx(20)
