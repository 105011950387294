.phone-submit
  display: block
  position: relative
  height: dpx(70)
  width: dpx(570)
  border: dpx(2) solid $dark
  border-radius: dpx(10)
  z-index: 1
  @media #{$tablet}
    width: 100%
    height: tpx(50)
    border: tpx(1) solid $dark
    border-radius: tpx(10)
  @media #{$mobile}
    width: 100%
    height: mpx(50)
    border: mpx(1) solid $dark
    border-radius: mpx(10)
  &--error 
    border-color: red !important
  .PhoneInput
    width: 100%
    height: 100%
    .PhoneInputCountry
      position: absolute
      left: 0
      top: 0
      width: dpx(60)
      height: 100%
      z-index: 2
      display: block
      @media #{$tablet}
        width: tpx(42)
      @media #{$mobile}
        width: mpx(42)
      select
        position: relative
        width: 100%
        height: 100%
        z-index: 2
        cursor: pointer
        opacity: 0
        appearance: none
        -webkit-appearance: none
    .PhoneInputCountryIcon
      position: absolute
      left: dpx(20)
      top: 50%
      transform: translateY(-50%)
      width: dpx(30)
      height: dpx(21)
      z-index: 1
      box-shadow: none
      @media #{$tablet}
        left: tpx(15)
        width: tpx(20)
        height: tpx(14)
      @media #{$mobile}
        left: mpx(15)
        width: mpx(20)
        height: mpx(14)
      img
        @include fitImage
    .PhoneInputCountrySelectArrow
      display: none
    input 
      position: relative
      top: 0
      left: 0
      display: block
      width: 100%
      height: 100%
      background: transparent
      border: none
      padding: dpx(23) dpx(301) dpx(23) dpx(60)
      z-index: 1
      @media #{$tablet}
        padding: tpx(16) tpx(15) tpx(16) tpx(42)
      @media #{$mobile}
        padding: mpx(16) mpx(15) mpx(16) mpx(42)
      &, &::placeholder
        color: $dark
        font-size: dpx(20)
        font-weight: 500
        line-height: dpx(24)
        text-align: left
        @media #{$tablet}
          font-size: tpx(15)
          line-height: tpx(18)
        @media #{$mobile}
          font-size: mpx(15)
          line-height: mpx(18)
  button 
    display: block
    position: absolute
    top: dpx(5)
    right: dpx(5)
    width: dpx(278)
    height: calc(100% - dpx(10))
    font-size: dpx(20)
    line-height: dpx(24)
    border-radius: dpx(5)
    font-weight: 600
    background: $dark
    color: $gray2
    text-align: center
    text-transform: uppercase
    transition: opacity 150ms $easeInOut
    z-index: 2
    @media #{$tablet}
      top: tpx(2)
      right: tpx(2)
      width: calc((100% - tpx(5)) / 2)
      height: calc(100% - tpx(4))
      font-size: tpx(15)
      line-height: tpx(18)
      border-radius: tpx(8)
      font-weight: 500
    @media #{$mobile}
      top: mpx(2)
      right: mpx(2)
      width: calc((100% - mpx(5)) / 2)
      height: calc(100% - mpx(4))
      font-size: mpx(15)
      line-height: mpx(18)
      border-radius: mpx(8)
      font-weight: 500
    &:hover 
      opacity: 0.5
    &:active
      opacity: 1
