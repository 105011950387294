.form-section
  position: relative
  z-index: 1
  width: 100%
  height: 100vh
  @media #{$tablet}
    height: auto
  &--visible
    .form-newness, .select, .form-bedrooms, .price-range, .phone-submit
      transform: translateY(0) !important
      opacity: 1 !important
  .form-newness, .select, .form-bedrooms, .price-range, .phone-submit
    opacity: 0
    transform: translateY(50px)
    transition-duration: 350ms
    transition-timing-function: $easeInOut
    transition-property: transform, opacity
  &__background
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    width: 100%
    height: 100%
    overflow: hidden
    z-index: -1
    background-color: $gray3
    @media #{$tablet}
      height: 100%
    @media #{$mobile}
      height: 100%
  &__sticky
    position: sticky
    top: 0
    width: 100%
    height: vh(100)
    margin: 0 auto
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: center
    @media #{$tablet}
      min-height: 100vh
      height: auto
      padding: tpx(86) tpx(55)
    @media #{$mobile}
      padding: mpx(100) mpx(20) mpx(50) mpx(20)
      justify-content: flex-start

  .form 
    width: 100vw
    height: 100%
    @media #{$tablet}
      margin: 0 auto
      width: tpx(450)
    @media #{$mobile}
      width: 100%

  .form-newness
    width: dpx(570)
    height: dpx(70)
    padding: dpx(5)
    border-radius: dpx(10)
    background-color: $dark
    display: flex
    align-items: center
    justify-content: space-between
    transition-delay: 0ms
    @media #{$tablet}
      width: 100%
      height: tpx(50)
      padding: tpx(2)
      border-radius: tpx(10)
    @media #{$mobile}
      width: 100%
      height: mpx(50)
      padding: mpx(2)
      border-radius: mpx(10)
    button 
      display: block
      height: 100%
      width: dpx(278)
      border-radius: dpx(5)
      font-size: dpx(20)
      font-weight: 500
      color: $gray3
      background-color: transparent
      transition: color 150ms $easeInOut, background-color 150ms $easeInOut
      @media #{$tablet}
        text-transform: uppercase
        width: calc((100% - tpx(1)) / 2)
        border-radius: tpx(8)
        font-size: tpx(15)
      @media #{$mobile}
        text-transform: uppercase
        width: calc((100% - mpx(1)) / 2)
        border-radius: mpx(8)
        font-size: mpx(15)
      &:hover 
        color: rgba($gray3, 0.5)
      &:active
        color: $gray3
      &.active
        background-color: $gray3
        color: $dark

  .select
    position: relative 
    width: dpx(570)
    height: dpx(70) 
    transition-delay: 100ms
    z-index: 5
    @media #{$tablet}
      width: 100%
      height: tpx(50)
    @media #{$mobile}
      width: 100%
      height: mpx(50)
    &__button
      position: relative
      display: block
      width: 100%
      height: 100%
      border: dpx(2) solid $dark
      border-radius: dpx(10)
      padding: dpx(20)
      display: flex
      justify-content: space-between
      align-items: center
      background-color: transparent
      @media #{$tablet}
        border: tpx(1) solid $dark
        border-radius: tpx(10)
        padding: tpx(16) tpx(10) tpx(16) tpx(15)
      @media #{$mobile}
        border: mpx(1) solid $dark
        border-radius: mpx(10)
        padding: mpx(16) mpx(10) mpx(16) mpx(15)
      span, img 
        transition: opacity 150ms $easeInOut
      &:hover 
        span, img
          opacity: 0.5
      img 
        display: block
        width: dpx(30)
        height: dpx(30)
        object-fit: contain
        transition: transform 300ms $easeInOut
        @media #{$tablet}
          width: tpx(20)
          height: tpx(20)
        @media #{$mobile}
          width: mpx(20)
          height: mpx(20)
      span 
        display: block
        font-size: dpx(20)
        font-weight: 500
        color: $dark
        @media #{$tablet}
          font-size: tpx(15)
        @media #{$mobile}
          font-size: mpx(15)
    &__list
      display: none
      position: absolute
      top: dpx(70)
      left: 0
      width: 100%
      padding: dpx(10) 0
      border: dpx(2) solid $dark
      border-radius: 0 0 dpx(10) dpx(10)
      background-color: $gray3
      z-index: 3
      overflow: hidden
      @media #{$tablet}
        top: tpx(50)
        padding: 0
        border: tpx(1) solid $dark
        border-radius: 0 0 tpx(10) tpx(10)
      @media #{$mobile}
        top: mpx(50)
        padding: 0
        border: mpx(1) solid $dark
        border-radius: 0 0 mpx(10) mpx(10)
      &-option 
        display: block
        background-color: transparent
        width: 100%
        padding: dpx(10)
        height: dpx(44)
        font-size: dpx(20)
        font-weight: 500
        color: $dark
        text-align: start
        transition: opacity 150ms $easeInOut
        @media #{$tablet}
          padding: tpx(10) 
          height: tpx(38)
          font-size: tpx(15)
        @media #{$mobile}
          padding: mpx(10) 
          height: mpx(38)
          font-size: mpx(15)
        &:hover 
          opacity: 0.5
        &:active
          opacity: 1
        &.active
          background-color: $dark
          color: $gray3
          &:hover 
            opacity: 1 
    &--opened 
      .select__list 
        display: block
      .select__button
        border-radius: dpx(10) dpx(10) 0 0
        border-bottom: none
        @media #{$tablet}
          border-radius: tpx(10) tpx(10) 0 0
        @media #{$mobile}
          border-radius: mpx(10) mpx(10) 0 0
        & > img 
          transform: rotate(180deg)        
    &--error 
      .select__button
        border-color: red !important    

  .form-bedrooms 
    width: dpx(570)
    transition-delay: 200ms
    @media #{$tablet}
      width: 100%
    @media #{$mobile}
      width: 100%
    &__title 
      font-size: dpx(20)
      font-weight: 500
      color: $dark
      text-transform: uppercase
      @media #{$tablet}
        font-size: tpx(15)
        font-weight: 700
      @media #{$mobile}
        font-size: mpx(15)
        font-weight: 700
    &__buttons
      margin-top: dpx(20)
      height: dpx(70)
      width: 100%
      display: flex
      align-items: center
      justify-content: space-between
      @media #{$tablet}
        margin-top: tpx(10)
        height: tpx(50)
      @media #{$mobile}
        margin-top: mpx(10)
        height: mpx(50)
      button
        display: block
        height: 100%
        width: dpx(53)
        border: dpx(2) solid $dark
        border-radius: dpx(10)
        font-size: dpx(30)
        font-weight: 500
        color: $dark
        background-color: transparent
        transition: background-color 250ms $easeInOut, opacity 150ms $easeInOut
        @media #{$tablet}
          width: tpx(40)
          border: tpx(1) solid $dark
          border-radius: tpx(10)
          font-size: tpx(15)
        @media #{$mobile}
          width: mpx(30)
          border: mpx(1) solid $dark
          border-radius: mpx(10)
          font-size: mpx(15)
        &.active
          color: $gray3
          background-color: $dark
          &:hover 
            opacity: 1
        &:hover 
          opacity: 0.5
        &:active
          opacity: 1

  .price-range
    margin-top: dpx(20)
    width: dpx(570)
    height: dpx(164)
    position: relative
    transition-delay: 300ms
    @media #{$tablet}
      margin-top: tpx(20)
      height: tpx(126)
      width: 100%
    @media #{$mobile}
      margin-top: mpx(20)
      height: mpx(126)
      width: 100%
    &__currency 
      display: flex
      align-items: center
      justify-content: flex-start
      gap: dpx(10)
      width: 100%
      @media #{$tablet}
        gap: 0
      @media #{$mobile}
        gap: 0
      h2 
        margin-right: auto
        font-size: dpx(20)
        font-weight: 500
        text-transform: uppercase
        @media #{$tablet}
          font-size: tpx(15)
          font-weight: 700
        @media #{$mobile}
          font-size: mpx(15)
          font-weight: 700
      button 
        width: dpx(80)
        height: dpx(40)
        font-size: dpx(20)
        border-radius: dpx(5)
        font-weight: 500
        text-align: center
        text-transform: uppercase
        background: transparent 
        transition: background-color 250ms $easeInOut, opacity 150ms $easeInOut
        @media #{$tablet}
          width: tpx(60)
          height: tpx(30)
          border-radius: tpx(5)
          font-size: tpx(15)
          font-weight: 400 
        @media #{$mobile}
          width: mpx(60)
          height: mpx(30)
          border-radius: mpx(5)
          font-size: mpx(15)
          font-weight: 400 
        &.active
          background-color: $dark
          color: $gray2
          &:hover 
            opacity: 1
        &:hover 
          opacity: 0.5
        &:active
          opacity: 1
    &__slider
      width: calc(100% - dpx(36))
      height: dpx(104)
      margin: dpx(20) auto 0 
      @media #{$tablet}
        width: calc(100% - tpx(36))
        height: tpx(76)
        margin: tpx(20) auto 0
      @media #{$mobile}
        width: calc(100% - mpx(36))
        height: mpx(76)
        margin: mpx(20) auto 0
      &::after 
        content: ''
        position: absolute
        top: 50%
        transform: translateY(-50%)
        left: dpx(-18)
        width: calc(100% + dpx(36))
        height: dpx(4)
        background-color: $dark
        @media #{$tablet}
          left: tpx(-18)
          width: calc(100% + tpx(36))
          height: tpx(4)
        @media #{$mobile}
          left: mpx(-18)
          width: calc(100% + mpx(36))
          height: mpx(4)
      .thumb-wrapper
        display: block
        position: relative 
        width: dpx(44)
        height: 100%
        background-color: $gray3
        cursor: pointer
        @media #{$tablet}
          width: tpx(28)
        @media #{$mobile}
          width: mpx(28)
        .thumb
          position: absolute
          content: ''
          top: 50%
          left: 50%
          transform: translate(-50%, -50%) 
          width: dpx(36)
          height: dpx(36)
          border-radius: 50%
          background-color: $gray3
          border: dpx(8) solid $dark
          @media #{$tablet}
            width: tpx(24)
            height: tpx(24)
            border: tpx(5) solid $dark
          @media #{$mobile}
            width: mpx(24)
            height: mpx(24)
            border: mpx(5) solid $dark
        .value 
          position: absolute
          top: 0
          left: 50%
          transform: translateX(-50%)
          height: dpx(24)
          font-size: dpx(20)
          font-weight: 700
          color: $dark
          text-align: center
          @media #{$tablet}
            height: tpx(18)
            font-size: tpx(15)
          @media #{$mobile}
            height: mpx(18)
            font-size: mpx(15)
        &::after 
          position: absolute
          content: ''
          bottom: 0
          left: 50%
          transform: translateX(-50%)
          font-size: dpx(20)
          font-weight: 500 
          text-transform: uppercase
          @media #{$tablet}
            font-size: tpx(15)
          @media #{$mobile}
            font-size: mpx(15)
        &-0::after 
          content: 'min'
        &-1::after 
          content: 'max'

  .phone-submit 
    margin-top: dpx(20)
    transition-delay: 400ms
    @media #{$tablet}
      margin-top: tpx(20)
      width: tpx(450)
    @media #{$mobile}
      margin-top: mpx(20)
      width: 100%