.home-page 
  position: relative
  width: 100%
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: flex-start

@import './main'
@import './about'
@import './services'
@import './types'
@import './news'
@import './form'
@import './contacts'
