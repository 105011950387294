$mobile: "only screen and (max-width: 600px) and (orientation: portrait)"
$tablet: "only screen and (max-width: 1200px) and (orientation: portrait)"
$desktop: "only screen and (min-width: 1440px)"
  
$white: #FFFFFF
$black: #000000

$dark: #1F1F23
$brown: #C9926A
$gray1: #F9F9F9
$gray2: #EBEBEB
$gray3: #E4E4E4
$gray4: #D9D9D9
$gray5: #C3C2C2

$easeInOutBack: cubic-bezier(0.68, -0.55, 0.27, 1.55)
$easeInOut: ease-in-out
$easeOut: ease-out
$Out: cubic-bezier(0.39, 0.58, 0.57, 1)
