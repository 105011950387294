.wip-page 
  position: relative
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: flex-start
  overflow-x: auto
  opacity: 0
  &--assets-loaded
    opacity: 1
  .background
    &::before
      content: ''
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: 100%
      background: rgba($black, 0.4)
      backdrop-filter: blur(7.5px)
      z-index: 4
  &__content
    position: relative
    top: 0
    left: 0
    width: 100%
    height: 100%
    padding: dpx(80) dpx(289) dpx(80) dpx(300)
    display: grid
    justify-content: center
    align-items: flex-start
    grid-template-columns: repeat(2, dpx(460)) dpx(411) 
    grid-template-rows: dpx(219) dpx(326) dpx(156) auto
    @media (min-aspect-ratio: 1/1) 
      @media (max-aspect-ratio: 1440/1080)
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        transform: scale(0.7)
        padding: 0 0 dpx(100) 0
    @media #{$tablet}
      padding: 0
      display: flex 
      flex-direction: column
      align-items: center
      justify-content: center
      transform: none
    @media #{$mobile}
      margin-top: auto
      padding: mpx(66) 0
      height: auto
      flex: none

  &__rights-desktop,
  &__contacts-whatsapp,
  &__contacts-phone,
  &__location,
  &__rights
    @include fadeInUpAnimation
    @at-root .wip-page--loaded &
      opacity: 1
      transform: translateY(0)
      pointer-events: unset
        
  &__logo 
    grid-column: 2/3
    grid-row: 1/2
    transition-delay: 600ms
    @include fadeInRightAnimation
    @at-root .wip-page--loaded &
      opacity: 1
      transform: translateX(0)
    @media #{$tablet}, (min-aspect-ratio: 1/1) and (max-aspect-ratio: 1440/1080)
      order: 2
      margin-top: dpx(79)
      transition-delay: 1100ms
      @include fadeInUpAnimation
      @at-root .wip-page--loaded &
        opacity: 1
        transform: translateY(0)
    @media #{$mobile}
      margin-top: mpx(79)
  &__progress
    grid-column: 1/2
    grid-row: 2/4
    @include fadeTransition
    transform: translateX(dpx(500))
    transition-duration: 1250ms
    @at-root .wip-page--loaded &
      transform: translateX(0)
    @media #{$tablet}, (min-aspect-ratio: 1/1) and (max-aspect-ratio: 1440/1080)
      order: 1
      transform: translateY(tpx(220))
      @at-root .wip-page--loaded &
        transform: translateY(0)
    @media  #{$mobile}
      transform: translateY(calc((vh(50)) - mpx(97) - mpx(66)))
      @at-root .wip-page--loaded &
        transform: translateY(0)
  &__disclaimer
    grid-column: 2/3
    grid-row: 2/3
    transition-delay: 800ms
    @include fadeInRightAnimation
    @at-root .wip-page--loaded &
      opacity: 1
      transform: translateX(0)
    @media #{$tablet}, (min-aspect-ratio: 1/1) and (max-aspect-ratio: 1440/1080)
      order: 1
      transition-delay: 1000ms
      @include fadeInDownAnimation
      transform: translateY(-20px)
      @at-root .wip-page--loaded &
        opacity: 1
        transform: translateY(0)
  &__contacts
    grid-column: 2/3
    grid-row: 3/4
    @media #{$tablet}, (min-aspect-ratio: 1/1) and (max-aspect-ratio: 1440/1080)
      order: 2
      margin-top: tpx(40)
    @media #{$mobile}
      margin-top: mpx(40)
    &-whatsapp
      transition-delay: 1000ms
      @media #{$tablet}, (min-aspect-ratio: 1/1) and (max-aspect-ratio: 1440/1080)
        transition-delay: 1200ms
    &-phone
      transition-delay: 1000ms
      @media #{$tablet}, (min-aspect-ratio: 1/1) and (max-aspect-ratio: 1440/1080)
        transition-delay: 1300ms
  &__location
    grid-column: 3/4
    grid-row: 3/4
    transition-delay: 1000ms
    @media #{$tablet}, (min-aspect-ratio: 1/1) and (max-aspect-ratio: 1440/1080)
      order: 2
      margin-top: tpx(40)
      transition-delay: 1400ms
    @media #{$mobile}
      margin-top: mpx(40)
  &__rights-desktop
    align-self: flex-end
    justify-self: flex-start
    grid-column: 2/3
    grid-row: 4/5
    transition-delay: 1200ms
  &__rights
    transition-delay: 1500ms

  &__logo
    display: block
    width: dpx(129)
    height: dpx(40)      
    @media #{$tablet}, (min-aspect-ratio: 1/1) and (max-aspect-ratio: 1440/1080)
      width: tpx(100)
      height: tpx(31)
    @media #{$mobile}
      width: mpx(100)
      height: mpx(31)    
  &__progress
    width: dpx(320)
    height: dpx(482)
    display: flex
    flex-direction: column
    gap: dpx(20)
    padding-top: dpx(162)
    @media #{$tablet}, (min-aspect-ratio: 1/1) and (max-aspect-ratio: 1440/1080)
      padding-top: tpx(94)
      width: tpx(100)
      height: tpx(194)
      gap: tpx(12)
    @media #{$mobile}
      padding-top: mpx(94)
      width: mpx(100)
      height: mpx(194)
      gap: mpx(12)      
    &-digits 
      width: dpx(86)
      transform: translateX(-50%)
      transition: left 2000ms cubic-bezier(.3,.1,.2,1)
      position: absolute
      left: 0
      top: 0
      @media #{$tablet}, (min-aspect-ratio: 1/1) and (max-aspect-ratio: 1440/1080)
        width: tpx(52)
      @media #{$mobile}
        width: mpx(52)
      span
        display: block
      &-percent 
        font-size: dpx(40)
        font-weight: 700
        line-height: dpx(48)
        letter-spacing: 0.02em
        text-align: center
        color: rgba($white, 0.5)
        @media #{$tablet}, (min-aspect-ratio: 1/1) and (max-aspect-ratio: 1440/1080)
          font-size: tpx(24)
          line-height: tpx(29)
        @media #{$mobile}
          font-size: mpx(24)
          line-height: mpx(29)  
      &-date 
        font-size: dpx(20)
        font-weight: 300
        line-height: dpx(24)
        letter-spacing: 0.02em
        text-align: center
        color: $white
        @media #{$tablet}, (min-aspect-ratio: 1/1) and (max-aspect-ratio: 1440/1080)
          font-size: tpx(12)
          line-height: tpx(14)
        @media #{$mobile}
          font-size: mpx(12)
          line-height: mpx(14)
    &-hr 
      display: block
      width: dpx(2)
      height: dpx(40)
      background: $white
      border: none
      color: $white
      transform: translateX(-50%)
      transition: left 2000ms cubic-bezier(.3,.1,.2,1)
      position: absolute
      left: 0
      top: dpx(92)
      @media #{$tablet}, (min-aspect-ratio: 1/1) and (max-aspect-ratio: 1440/1080)
        top: tpx(56)
        width: tpx(1)
        height: tpx(20)
      @media #{$mobile}
        top: mpx(56)
        width: mpx(1)
        height: mpx(20)
    &-figure
      margin-top: dpx(30)
      display: block
      width: 100%
      height: 100%
      margin-top: auto
      rect 
        width: 0
        transition: width 2000ms cubic-bezier(.3,.1,.2,1)
  &__disclaimer 
    width: dpx(400)
    font-size: dpx(40)
    font-weight: 400
    line-height: dpx(48)
    letter-spacing: 0.02em
    text-align: left
    text-transform: uppercase
    color: $white
    @media #{$tablet}, (min-aspect-ratio: 1/1) and (max-aspect-ratio: 1440/1080)
      width: tpx(193)
      font-size: tpx(20)
      margin-top: tpx(24)
      line-height: tpx(24)
      text-align: center
    @media #{$mobile}
      width: mpx(193)
      font-size: mpx(20)
      line-height: mpx(24)     
      margin-top: mpx(24) 
  &__contacts
    display: flex
    align-items: flex-start
    flex-direction: column    
    gap: dpx(60)
    @media #{$tablet}, (min-aspect-ratio: 1/1) and (max-aspect-ratio: 1440/1080)
      align-items: center
      gap: tpx(40)
    @media #{$mobile}
      gap: mpx(40)    
    & > a
      display: inline-block
      height: dpx(48)
      font-size: dpx(40)
      font-weight: 500
      line-height: dpx(48)
      text-align: left
      color: $white
      pointer-events: none
      @media #{$tablet}, (min-aspect-ratio: 1/1) and (max-aspect-ratio: 1440/1080)
        height: tpx(24)
        font-size: tpx(20)
        line-height: tpx(24)
        text-align: center
      @media #{$mobile}
        height: mpx(24)
        font-size: mpx(20)
        line-height: mpx(24)
      span
        font-size: inherit
        font-weight: inherit
        line-height: inherit
        color: inherit
        border-bottom: 3px solid $white
        @include link
        @media #{$tablet}
          border-width: 2px
        @media #{$mobile}
          border-width: 1px
  &__location
    & > a
      @include link
      @media #{$tablet}, (min-aspect-ratio: 1/1) and (max-aspect-ratio: 1440/1080)
        display: flex
        flex-direction: column
        align-items: center
    &-link
      display: inline-block
      height: dpx(48)
      font-size: dpx(40)
      font-weight: 500
      line-height: dpx(48)
      text-align: left
      color: $white
      border-bottom: dpx(3) solid $white
      @media #{$tablet}, (min-aspect-ratio: 1/1) and (max-aspect-ratio: 1440/1080)
        height: tpx(24)
        font-size: tpx(20)
        line-height: tpx(24)
        text-align: center
      @media #{$mobile}
        height: mpx(24)
        font-size: mpx(20)
        line-height: mpx(24)
    &-info 
      margin-top: dpx(24)  
      @media #{$tablet}, (min-aspect-ratio: 1/1) and (max-aspect-ratio: 1440/1080)
        margin-top: tpx(24)
      @media #{$mobile}
        margin-top: mpx(24)      
      span
        display: block
        font-size: dpx(20)
        line-height: dpx(28)
        text-align: left
        color: $white
        @media #{$tablet}, (min-aspect-ratio: 1/1) and (max-aspect-ratio: 1440/1080)
          width: tpx(290)
          font-size: tpx(14)
          line-height: tpx(22)
          text-align: center
        @media #{$mobile}
          width: mpx(290)
          font-size: mpx(14)
          line-height: mpx(22)
          text-align: center
        &:first-child
          font-weight: 600
        &:last-child
          font-weight: 400
  &__rights-desktop
    font-size: dpx(18)
    font-weight: 400
    line-height: dpx(22)
    color: $white
    text-align: left
    @media #{$tablet}, (min-aspect-ratio: 1/1) and (max-aspect-ratio: 1440/1080)
      display: none
  &__rights
    z-index: 2
    font-weight: 400
    text-align: center
    color: $white
    text-transform: uppercase
    @media #{$tablet}, (min-aspect-ratio: 1/1) and (max-aspect-ratio: 1440/1080)
      text-align: center
      font-size: tpx(14)
      line-height: tpx(17)
      width: 100%
    @media #{$mobile}
      bottom: mpx(24)
      font-size: mpx(14)
      line-height: mpx(17)    
      width: 100%
      text-align: center
    @media #{$mobile} and (min-aspect-ratio: 596/1000) and (max-aspect-ratio: 1/1)
      position: relative
      margin-top: mpx(40)
    &-overlay
      display: none
      overflow: hidden
      position: fixed
      bottom: 0
      padding-bottom: dpx(40)
      width: 100%
      flex: none
      @media #{$tablet}, (min-aspect-ratio: 1/1) and (max-aspect-ratio: 1440/1080)
        display: block
      @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 1440/1080)
        transform: scale(0.7)
      @media #{$tablet}
        padding-bottom: tpx(40)
        position: relative
        transform: none
        bottom: unset
      @media #{$mobile}
        padding-bottom: mpx(24)
        margin-bottom: auto
