.property-types-page
  position: relative
  width: 100%
  height: vh(100)
  padding: dpx(333) dpx(55) 0
  @media #{$tablet}
    padding: tpx(168) 0 tpx(68) 0
  @media #{$mobile}
    padding: mpx(134) mpx(20) mpx(68) mpx(20)
  &__marquee 
    position: absolute
    left: 50% 
    top: dpx(174)
    transform: translateX(-50%)
    width: 100vw
    display: flex
    overflow: hidden
    &:hover
      .property-types-page__marquee-slide
        animation-play-state: paused
        @media #{$tablet}
          animation-play-state: running
    @media #{$tablet}
      top: tpx(100)
    @media #{$mobile}
      top: mpx(56)
    &-slide 
      display: flex
      animation: 25s marquee infinite linear
    h1
      margin: 0 dpx(25)
      white-space: nowrap
      font-size: dpx(80)
      font-weight: 500
      line-height: dpx(97)
      text-align: left
      color: $dark
      opacity: 0.1
      cursor: pointer
      transition: opacity 250ms $easeInOut
      @media #{$tablet}
        margin: 0 tpx(10)
        font-size: tpx(40)
        line-height: tpx(48)
        font-weight: 700
      @media #{$mobile}
        margin: 0 mpx(10)
        font-size: mpx(40)
        line-height: mpx(48)
        font-weight: 700
      &.active 
        opacity: 1 !important
      &:hover
        opacity: 1
        @media (any-pointer: coarse)
          opacity: 0.1

  &__content 
    margin: dpx(30) 0 0 0
    display: grid 
    grid-template-columns: auto dpx(570)
    grid-template-rows: dpx(8) auto dpx(70)
    justify-content: space-between
    height: dpx(496)
    gap: dpx(30) dpx(50)
    @media #{$tablet}
      grid-template-columns: auto 
      grid-template-rows: 1fr tpx(32) tpx(198) tpx(80)
      gap: 0
      margin: 0 auto
      height: auto 
      width: tpx(450)
      padding-bottom: tpx(68)
    @media #{$mobile}
      display: flex
      flex-direction: column
      align-items: flex-start
      justify-content: flex-start
      gap: mpx(30)
      margin: 0
      height: 100% 
      width: 100%
      padding-bottom: 0

  .progress-bar
    grid-row: 1 / 2
    grid-column: 1 / 3
    @media #{$tablet}
      grid-row: 2 / 3
      grid-column: 1 / 2
      align-self: flex-end
    @media #{$mobile}
      display: none
  &__slider 
    grid-row: 2 / 4
    grid-column: 1 / 2 
    @media #{$tablet}
      grid-row: 3 / 4
      grid-column: 1 / 2
      height: tpx(178)
      align-self: flex-end
    @media #{$mobile}
      order: 2
      height: mpx(188)
      flex: none
  &__description 
    grid-row: 2 / 3
    grid-column: 2 / 3
    @media #{$tablet}
      grid-row: 1 / 2
      grid-column: 1 / 2
    @media #{$mobile}
      flex-grow: 1
      order: 1
  .form 
    grid-row: 3 / 4
    grid-column: 2 / 3
    @media #{$tablet}
      grid-row: 4 / 5
      grid-column: 1 / 2
      align-self: flex-end
    @media #{$mobile}
      flex: none
      order: 3

  &__slider
    position: relative
    max-width: dpx(1190)
    margin: 0
    border-radius: dpx(10)
    @media #{$mobile}
      width: 100%
      max-width: unset 
      border-radius: mpx(10)
    .swiper-wrapper 
      width: 100%
      height: 100%
    .swiper-slide 
      height: 100%
    img 
      object-fit: cover
      display: block
      width: 100%
      height: 100%
      border-radius: dpx(10)
      background-color: $gray5
      @media #{$tablet}
        border-radius: tpx(10)
      @media #{$mobile}
        border-radius: mpx(10)
    .swiper-pagination
      bottom: dpx(20)
      right: dpx(20)
      transform: none
      left: auto 
      top: auto
      display: flex
      flex-direction: column
      gap: dpx(15)
      @media #{$tablet}
        bottom: tpx(10)
        right: tpx(10)
        gap: tpx(5)
      @media #{$mobile}
        bottom: mpx(10)
        right: mpx(10)
        gap: mpx(5)
      &-bullet 
        margin: 0 !important
        width: dpx(15)
        height: dpx(15)
        background-color: transparent
        border: dpx(2) solid $gray2 
        opacity: 1
        transition: background-color 250ms $easeInOut
        @media #{$tablet}
          width: tpx(6)
          height: tpx(6)
          border: tpx(1) solid $gray2
        @media #{$mobile}
          width: mpx(6)
          height: mpx(6)
          border: mpx(1) solid $gray2
        &-active 
          background-color: $gray2 

  &__description
    width: dpx(570)
    margin-bottom: dpx(9)
    @media #{$tablet}
      margin-bottom: 0
      width: 100%
    .swiper-slide
      height: auto
      white-space: pre-wrap
      font-size: dpx(20)
      line-height: dpx(24)
      padding-right: dpx(10)
      @media #{$tablet}
        font-size: tpx(14)
        line-height: tpx(17)
        padding-right: tpx(10)
      @media #{$mobile}
        font-size: mpx(14)
        line-height: mpx(17)
        padding-right: mpx(10)
    .swiper-scrollbar
      width: 6px
      background: linear-gradient(90deg, transparent 33%, $dark 33%, $dark 66%, transparent 66%)
      &-drag
        width: 6px
        background-color: $dark
        border-radius: 3px
        cursor: pointer