$intermediate-larger: "(min-aspect-ratio: 1440 / 1080) and (max-aspect-ratio: 1700 / 1080)"
$intermediate-smaller: "(min-aspect-ratio: 1 / 1) and (max-aspect-ratio: 1440 / 1080)"
$intermediate-links: "(min-aspect-ratio: 1 / 1) and (max-aspect-ratio: 1250 / 1080)"
$intermediate: "(min-aspect-ratio: 1 / 1) and (max-aspect-ratio: 1700 / 1080)"
  
.interface
  position: fixed
  left: 50%
  transform: translateX(-50%)
  width: calc(vh(100) * (16 / 9))
  max-width: 100%
  --color: #{$dark}
  @media #{$mobile}
    &::before,
    &::after
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: mpx(46)
      background-color: $gray2
      z-index: 1
    &::after
      top: unset
      bottom: 0
      height: mpx(48)
  &--top
    top: 0
    z-index: 101
    @media #{$mobile}
      &::before
        content: ''
  &--bottom
    bottom: 0
    z-index: 100
    @media #{$mobile}
      &::after
        content: ''
  &--white
    --color: #{$white}
    &::before,
    &::after
      content: unset
  &__background
    position: absolute
    left: 50%
    top: 50%
    transform: translateX(-50%)
    width: 100vw
    height: vh(100)
    pointer-events: none
    opacity: 0
    background-color: $dark
    transition: opacity 250ms $easeInOut
    @at-root .interface--menu-open &
      opacity: 1
  &__logo-min
    position: absolute
    left: dpx(55)
    top: dpx(50)
    width: dpx(24)
    height: dpx(24)
    z-index: 3
    opacity: 1
    transition: opacity 250ms $easeInOut
    animation: fade-bottom 400ms $easeOut forwards
    @media #{$tablet}
      animation: fade-top 400ms $easeOut forwards
      left: tpx(55)
      top: unset
      bottom: tpx(20)
      width: tpx(24)
      height: tpx(24)
    @media #{$mobile}
      animation: fade-top 400ms $easeOut forwards
      padding: mpx(20)
      left: 0
      bottom: 0
      width: mpx(56)
      height: mpx(56)
    @at-root .interface--bottom &
      display: none
      @media #{$tablet}
        display: block
    @at-root .interface--top &
      display: block
      @media #{$tablet}
        display: none
    @at-root .interface--menu-open &
      @media #{$tablet}
        pointer-events: none
        opacity: 0 !important
      @media #{$mobile}
        pointer-events: none
        opacity: 0 !important
    svg
      @include fitImage
      @media #{$mobile}
        width: mpx(16)
        height: mpx(16)
      path
        transition: fill 250ms $easeInOut
        fill: var(--color)
  &__logo-fill
    position: absolute
    left: unset
    right: dpx(55)
    top: dpx(50)
    width: dpx(77)
    height: dpx(24)
    z-index: 3
    animation: fade-bottom 400ms $easeOut forwards
    @media #{$tablet}
      left: tpx(55)
      top: tpx(20)
      width: tpx(77)
      height: mpx(24)
      right: auto
    @media #{$mobile}
      padding: mpx(20)
      left: 0
      top: 0
      width: mpx(91)
      height: mpx(56)
      right: auto
    svg
      @include fitImage
      @media #{$mobile}
        width: mpx(51)
        height: mpx(16)
      path
        transition: fill 250ms $easeInOut
        fill: var(--color)

  &__left-link
    display: flex
    align-items: center
    justify-content: flex-start
    gap: dpx(50)
    animation: fade-top 400ms $easeOut forwards
    @media #{$tablet}
      display: none
    @media #{$mobile}
      display: none
    &-wrapper 
      position: absolute
      left: dpx(55)
      bottom: dpx(50)
      z-index: 3
      opacity: 1
      transition: opacity 250ms $easeInOut
    @at-root .interface--menu-open &
      pointer-events: none
      opacity: 0
    a
      display: flex
      align-items: center
      justify-content: flex-start
      gap: dpx(10)
      transition: opacity 150ms $easeInOut
      &:hover
        opacity: 0.5
      &:active
        opacity: 1
      .circle
        transition: border 250ms $easeInOut
        position: relative
        width: dpx(30)
        height: dpx(30)
        border: dpx(2) solid var(--color)
        border-radius: 50%
        @media #{$intermediate-links}
          width: dpx(27)
          height: dpx(27)
        span
          position: absolute
          left: 50%
          top: 50%
          transform: translate(-50%, -50%)
          font-size: dpx(20)
          font-weight: 500
          color: var(--color)
          transition: color 250ms $easeInOut
          text-transform: uppercase
          @media #{$intermediate-links}
            font-size: dpx(18)
      p
        font-size: dpx(20)
        font-weight: 500
        text-transform: uppercase
        color: var(--color)
        transition: color 250ms $easeInOut
        @media #{$intermediate-links}
          font-size: dpx(18)
  &__middle-link
    display: flex
    align-items: center
    justify-content: center
    animation: fade-top 400ms $easeOut forwards
    @media #{$tablet}
      display: none
    @media #{$mobile}
      display: none
    &-wrapper
      position: absolute
      left: 50%
      bottom: dpx(53)
      transform: translate(-50%, 0)
      z-index: 3
      opacity: 1
      transition: opacity 250ms $easeInOut
      @at-root .interface--menu-open &
        pointer-events: none
        opacity: 0
    a
      position: relative
      font-size: dpx(20)
      font-weight: 500
      text-transform: uppercase
      color: var(--color)
      transition: color 250ms $easeInOut, opacity 150ms $easeInOut
      margin-left: dpx(30)
      &:hover
        opacity: 0.5
      &:active
        opacity: 1
      @media #{$intermediate-links}
        font-size: dpx(18)
      &::before
        content: ''
        position: absolute
        left: dpx(-15)
        width: dpx(2)
        height: 100%
        background-color: var(--color)
        transition: background-color 250ms $easeInOut
        pointer-events: none
      &:nth-child(1)
        margin-left: 0
        &::before
          content: unset
  &__right-link
    display: flex
    align-items: center
    justify-content: flex-end
    gap: dpx(10)
    animation: fade-top 400ms $easeOut forwards
    @media #{$tablet}
      gap: tpx(10)
    @media #{$mobile}
      gap: mpx(10)
    &-wrapper
      position: absolute
      right: dpx(55)
      bottom: dpx(50)
      z-index: 3
      opacity: 1
      transition: opacity 150ms $easeInOut
      &:hover
        opacity: 0.5
      &:active
        opacity: 1
      @media #{$tablet}
        right: tpx(55)
        bottom: tpx(20)
      @media #{$mobile}
        padding: mpx(20)
        right: 0
        bottom: 0
        transform: none
      @at-root .interface--menu-open &
        pointer-events: none
        opacity: 0
    p
      font-size: dpx(20)
      font-weight: 700
      text-transform: uppercase
      color: var(--color)
      transition: color 250ms $easeInOut
      @media #{$intermediate-links}
        font-size: dpx(18)
      @media #{$tablet}
        font-size: tpx(20)
      @media #{$mobile}
        font-size: mpx(15)
    svg
      @include fitImage
      width: dpx(30)
      height: dpx(30)
      transition: transform 250ms $easeInOut
      @media #{$intermediate-links}
        width: dpx(27)
        height: dpx(27)
      @media #{$tablet}
        width: tpx(30)
        height: tpx(30)
      @media #{$mobile}
        width: mpx(16)
        height: mpx(16)
      &.up
        transform: rotate(180deg)
      &.down
        transform: rotate(0deg)
      &.left
        transform: rotate(90deg)
      &.right
        transform: rotate(-90deg)
      rect
        stroke: var(--color)
        transition: stroke 250ms $easeInOut
      path
        fill: var(--color)
        transition: fill 250ms $easeInOut

  &__burger
    display: block
    position: absolute
    left: 50%
    top: dpx(40)
    padding: dpx(10)
    transform: translate(-50%)
    z-index: 3
    @media #{$tablet}
      top: tpx(20)
      right: tpx(45)
      padding: tpx(10)
      left: auto
      transform: none
    @media #{$mobile}
      top: 0
      right: 0
      left: auto
      padding: mpx(20)
      transform: none
    &-wrapper
      display: flex
      flex-direction: column
      align-items: center
      justify-content: flex-start
      animation: fade-bottom 400ms $easeOut forwards
      height: dpx(24)
      @media #{$tablet}
        height: tpx(24)
      @media #{$mobile}
        height: mpx(16)
    &-bar
      width: dpx(64)
      transition: background-color 250ms $easeInOut
      background-color: var(--color)
      transition-property: width, transform, top, opacity
      transition-duration: 250ms, 250ms, 250ms, 300ms
      transition-timing-function: $easeInOut
      opacity: 1
      @media #{$tablet}
        width: tpx(64)
      @media #{$mobile}
        width: mpx(32)
      @at-root .interface__burger:active &
        opacity: 0.5

      &:nth-child(1)
        height: dpx(3)
        @media #{$tablet}
          height: tpx(3)
        @media #{$mobile}
          height: mpx(3)
        @at-root .interface--menu-open &
          opacity: 0

      &:nth-child(2),
      &:nth-child(3)
        @at-root .interface--menu-open &
          top: dpx(8)
          height: dpx(3)
          @media #{$tablet}
            top: tpx(8)
            height: tpx(3)
          @media #{$mobile}
            top: mpx(8)
            height: mpx(3)
        @at-root .interface__burger:hover &
          @media (any-pointer: fine)
            transform: translate(-50%, 0) 
            top: 0

        @at-root .interface__burger:active &
          transform: translate(-50%, 0) 
          top: 0

      &:nth-child(2)
        position: absolute
        left: 50%
        top: dpx(12)
        height: dpx(2)
        transform: translateX(-50%)
        transition-duration: 250ms, 300ms, 100ms
        transition-timing-function: $easeInOut, $easeInOutBack, $easeInOut, $easeInOut
        transition-delay: 200ms
        @at-root .interface__burger:hover &
          transition-delay: 0ms
        @media #{$tablet}
          top: tpx(12)
          height: tpx(2)
        @media #{$mobile}
          top: mpx(8)
          height: mpx(2)
        @at-root .interface--menu-open &
          transform: translateX(-50%) rotate(15deg)

      &:nth-child(3)
        position: absolute
        left: 50%
        top: dpx(23)
        height: dpx(1)
        transform: translateX(-50%)
        transition-duration: 300ms, 350ms, 150ms
        transition-timing-function: $easeInOut, $easeInOutBack, $easeInOut, $easeInOut
        transition-delay: 200ms
        @at-root .interface__burger:hover &
          transition-delay: 0ms
        @media #{$tablet}
          top: tpx(23)
          height: tpx(1)
        @media #{$mobile}
          top: mpx(15)
          height: mpx(1)
        @at-root .interface--menu-open &
          transform: translateX(-50%) rotate(-15deg)

    &-text
      position: absolute
      left: 50%
      top: dpx(-4)
      transform: translate(-50%, 0) 
      font-size: dpx(20)
      font-weight: 500
      text-transform: uppercase
      color: var(--color)
      opacity: 0
      transition-property: opacity, transform, top
      transition-duration: 200ms
      transition-delay: 0ms
      transition-timing-function: $easeInOut
      @media #{$tablet}
        display: none
      @media #{$mobile}
        display: none
      @at-root .interface__burger:hover &
        transition-delay: 200ms
        @media (any-pointer: fine)
          opacity: 1
          transform: translate(-50%, dpx(8)) 
      @at-root .interface__burger:active &
        opacity: 0.5
        transform: translate(-50%, dpx(8)) 
        
  &__menu
    position: absolute
    left: 50%
    top: 50%
    transform: translateX(-50%)
    width: 100%
    height: vh(100)
    pointer-events: none
    opacity: 0
    transition: opacity 250ms $easeInOut
    z-index: 2
    overflow: hidden auto
    display: flex
    align-items: stretch
    justify-content: space-between
    padding: dpx(174) dpx(55) dpx(50) dpx(55)
    gap: dpx(40)
    @media #{$tablet}
      height: calc(1 * vh(100))
      padding: tpx(120) tpx(50) tpx(50) tpx(50)
      flex-direction: column
      overflow: auto
      gap: mpx(50)
    @media #{$mobile}
      height: calc(1 * vh(100))
      padding: mpx(86) mpx(20) mpx(50) mpx(20)
      flex-direction: column
      overflow: auto
      gap: mpx(50)
    &::-webkit-scrollbar
      display: none
    @at-root .interface--menu-open &
      pointer-events: auto
      opacity: 1
    &-links
      position: sticky
      top: 0
      left: 0
      display: flex
      flex-direction: column
      align-items: flex-start
      justify-content: flex-start
      gap: dpx(20)
      @media #{$tablet}
        gap: tpx(10)
        position: relative
      @media #{$mobile}
        gap: mpx(10)
        position: relative
      &-link
        display: flex
        align-items: center
        justify-content: flex-start
        font-size: dpx(80)
        color: $white
        opacity: 1
        transition: opacity 150ms $easeInOut
        @media #{$intermediate-larger}
          font-size: dpx(64)
        @media #{$intermediate-smaller}
          font-size: dpx(56)
        @media #{$tablet}
          font-size: tpx(64)
        @media #{$mobile}
          font-size: mpx(40)
        &:hover
          opacity: 0.2
        &:active
          opacity: 1
    &-news
      display: flex
      flex-direction: column
      align-items: flex-start
      justify-content: flex-start
      margin-left: auto
      gap: dpx(40)
      @media #{$intermediate-larger}
        width: dpx(570)
      @media #{$intermediate-smaller}
        width: dpx(500)
      @media #{$tablet}
        display: none
      @media #{$mobile}
        display: none
      &__title
        display: flex
        align-items: center
        justify-content: flex-start
        gap: dpx(40)
        font-size: dpx(80)
        color: $white
        margin-bottom: dpx(10)
        @media #{$intermediate-larger}
          font-size: dpx(64)
        @media #{$intermediate-smaller}
          font-size: dpx(56)
        span
          font-size: inherit
          font-weight: 700
          color: $gray5
          opacity: 0.2
      & > a:last-child
        padding-bottom: dpx(120)
      .news-section__item
        --color: #{$white}
        top: 0 !important
        height: auto !important
        &:hover
          .news-section__item__date
            &::before
              background-color: transparentize($gray5, 0) !important
          .news-section__item__text
            opacity: 1
        &__date
          &::before
            background-color: transparentize($gray5, 0.8) !important
        &__text
          opacity: 0.2
        &__preview
          @media #{$intermediate-larger}
            width: dpx(570)
          @media #{$intermediate-smaller}
            width: dpx(500)
    &-footer 
      display: none
      @media #{$tablet}
        display: grid
        gap: tpx(20)
        grid-template-columns: repeat(2, 1fr)
        grid-template-rows: repeat(2, 1fr)
      @media #{$mobile}
        display: grid
        gap: mpx(20)
        grid-template-columns: repeat(2, 1fr)
        grid-template-rows: repeat(2, 1fr)
      &-link 
        font-weight: 500
        text-transform: uppercase
        color: $gray2
        @media #{$tablet}
          font-size: tpx(25)
        @media #{$mobile}
          font-size: mpx(15)
  &__arrow 
    opacity: 0
    width: 50px
    height: 50px
    position: absolute
    z-index: 2
    bottom: 70px
    left: 50%
    @media #{$mobile}
      transition: opacity 500ms $easeInOut 
      animation: fadeIn 1000ms $easeInOut, arrowBouncing 1300ms infinite linear
      opacity: 1
    &--invisible 
      opacity: 0 !important
