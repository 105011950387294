.main-section
  width: 100%
  height: 300vh
  @media #{$tablet}
    height: 300vh
  @media #{$mobile}
    height: 300vh
  .background
    --opacity: 0
    &::before
      content: ''
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: 100%
      background-color: $gray2
      z-index: 4
      opacity: calc(1 - var(--opacity))
  &__sticky
    --scale: 1
    position: sticky
    top: 0
    width: 100%
    height: vh(100)
    overflow: hidden
  &__loader
    --opacity: 1
    position: relative
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    font-size: dpx(20)
    font-weight: 500
    text-transform: uppercase
    opacity: var(--opacity)
    @media #{$tablet}
      font-size: tpx(20)
    @media #{$mobile}
      font-size: mpx(20)
    button
      font-size: inherit
      font-weight: inherit
      text-transform: inherit
  &__letters
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    transform: scale(var(--scale))
    pointer-events: none
    z-index: 1
    &--outline
      z-index: 3
  .letter
    --x: 0
    --tablet-x: 0
    --mobile-x: 0
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%) translateX(calc(var(--x) * 1px))
    @media #{$tablet}
      transform: translate(-50%, -50%) translateX(calc(var(--tablet-x) * 1px))
    @media #{$mobile}
      transform: translate(-50%, -50%) translateX(calc(var(--mobile-x) * 1px))
    svg
      display: block
      width: 100%
    &--z
      width: dpx(284)
      @media #{$tablet}
        width: tpx(67)
      @media #{$mobile}
        width: mpx(67)
    &--i
      width: dpx(80)
      @media #{$tablet}
        width: tpx(19)
      @media #{$mobile}
        width: mpx(19)
    &--l
      width: dpx(224)
      @media #{$tablet}
        width: tpx(52)
      @media #{$mobile}
        width: mpx(52)
    &--a
      width: dpx(354)
      @media #{$tablet}
        width: tpx(83)
      @media #{$mobile}
        width: mpx(83)
  .image
    --x: 0
    --y: 0
    --dx: 0
    --dy: 0
    --tablet-x: 0
    --mobile-x: 0
    --progress: 0

    position: absolute
    top: 0
    left: 50%
    border-radius: dpx(10)
    overflow: hidden
    z-index: 2
    transition: translate 500ms $Out, opacity 350ms $Out
    transition-delay: 300ms
    transform: translateX(-50%) translateX(calc(var(--x) * 1px)) translate(calc(var(--dx) * 1px), calc(var(--dy) * 1px))
    opacity: 0
    @media #{$tablet}
      border-radius: tpx(10)
      transform: translateX(-50%) translateX(calc(var(--tablet-x) * 1px))
    @media #{$mobile}
      border-radius: mpx(10)
      transform: translateX(-50%) translateX(calc(var(--mobile-x) * 1px))
    &.image--in
      translate: 0 0
      opacity: 1
    &--1,
    &--3,
    &--5,
    &--6,
    &--8,
    &--10
      translate: 0 vh(-50)
      @media #{$tablet}
        translate: none
      @media #{$mobile}
        translate: none
      &.image--out
        translate: 0 vh(-50)
        opacity: 0
        @media #{$tablet}
          translate: none
        @media #{$mobile}
          translate: none
    &--2,
    &--4,
    &--7,
    &--9
      translate: 0 vh(50)
      @media #{$tablet}
        translate: none
      @media #{$mobile}
        translate: none
      &.image--out
        translate: 0 vh(50)
        opacity: 0
        @media #{$tablet}
          translate: none
        @media #{$mobile}
          translate: none
    &--1,
    &--2,
    &--3,
    &--4,
    &--5
      @media #{$tablet}
        translate: 0 vh(-50)
        &.image--out
          translate: 0 vh(-50)
      @media #{$mobile}
        translate: 0 vh(-50)
        &.image--out
          translate: 0 vh(-50)
    &--6,
    &--7,
    &--8,
    &--9,
    &--10
      @media #{$tablet}
        translate: 0 vh(50)
        &.image--out
          translate: 0 vh(50)
      @media #{$mobile}
        translate: 0 vh(50)
        &.image--out
          translate: 0 vh(50)
    img
      @include fillImage
      position: absolute
      left: 50%
      top: 50%
      border-radius: inherit
      width: 120%
      height: 120%
      transform: translate(-50%, -50%) translateX(calc((var(--progress) - 0.5) * 10%)) translate(calc(var(--dx) * -0.5px), calc(var(--dy) * -0.5px))
      transition: filter 200ms $easeInOut
      filter: grayscale(1)
      @media #{$tablet}
        filter: none
      @media #{$mobile}
        filter: none
      &:hover
        filter: none
    &--1
      top: dpx(174)
      width: dpx(462)
      height: dpx(260)
      @media #{$tablet}
        top: tpx(8)
        width: tpx(231)
        height: tpx(130)
      @media #{$mobile}
        top: mpx(8)
        width: mpx(231)
        height: mpx(130)
    &--2
      top: dpx(484)
      width: dpx(462)
      height: dpx(462)
      @media #{$tablet}
        top: tpx(163)
        width: tpx(231)
        height: tpx(231)
      @media #{$mobile}
        top: mpx(163)
        width: mpx(231)
        height: mpx(231)
    &--3
      top: dpx(224)
      width: dpx(260)
      height: dpx(462)
      @media #{$tablet}
        top: tpx(33)
        width: tpx(130)
        height: tpx(231)
      @media #{$mobile}
        top: mpx(33)
        width: mpx(130)
        height: mpx(231)
    &--4
      top: dpx(636)
      width: dpx(462)
      height: dpx(260)
      @media #{$tablet}
        top: tpx(239)
        width: tpx(231)
        height: tpx(130)
      @media #{$mobile}
        top: mpx(239)
        width: mpx(231)
        height: mpx(130)
    &--5
      top: dpx(124)
      width: dpx(462)
      height: dpx(462)
      @media #{$tablet}
        top: tpx(-17)
        width: tpx(231)
        height: tpx(231)
      @media #{$mobile}
        top: mpx(-17)
        width: mpx(231)
        height: mpx(231)
    &--6
      top: dpx(174)
      width: dpx(462)
      height: dpx(260)
      @media #{$tablet}
        top: tpx(446)
        width: tpx(231)
        height: tpx(130)
      @media #{$mobile}
        top: mpx(446)
        width: mpx(231)
        height: mpx(130)
    &--7
      top: dpx(484)
      width: dpx(462)
      height: dpx(462)
      @media #{$tablet}
        top: tpx(599)
        width: tpx(231)
        height: tpx(231)
      @media #{$mobile}
        top: mpx(599)
        width: mpx(231)
        height: mpx(231)
    &--8
      top: dpx(224)
      width: dpx(260)
      height: dpx(462)
      @media #{$tablet}
        top: tpx(469)
        width: tpx(130)
        height: tpx(231)
      @media #{$mobile}
        top: mpx(469)
        width: mpx(130)
        height: mpx(231)
    &--9
      top: dpx(636)
      width: dpx(462)
      height: dpx(260)
      @media #{$tablet}
        top: tpx(675)
        width: tpx(231)
        height: tpx(130)
      @media #{$mobile}
        top: mpx(675)
        width: mpx(231)
        height: mpx(130)
    &--10
      top: dpx(124)
      width: dpx(462)
      height: dpx(462)
      @media #{$tablet}
        top: tpx(419)
        width: tpx(231)
        height: tpx(231)
      @media #{$mobile}
        top: mpx(419)
        width: mpx(231)
        height: mpx(231)
