.form 
  position: relative
  top: 0
  left: 0
  width: 100%
  height: auto
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  gap: dpx(40)
  @media #{$tablet}
    gap: tpx(20)
  @media #{$mobile}
    gap: mpx(20)