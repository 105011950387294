@keyframes fade-right
  0%
    opacity: 0
    transform: translateX(-50px)
  100%
    opacity: 1
    transform: translateX(0)

@keyframes fade-top
  0%
    opacity: 0
    transform: translateY(100px)
  100%
    opacity: 1
    transform: translateX(0)

@keyframes fade-bottom
  0%
    opacity: 0
    transform: translateY(-100px)
  100%
    opacity: 1
    transform: translateX(0)
    
@keyframes move-figure
  0%
    transform: translateX(dpx(500))
  100%
    transform: translateX(0)

@keyframes fadeIn
  0%
    opacity: 0
  100%
    opacity: 1

@keyframes marquee
  0%
    transform: translateX(0)
  100%
    transform: translateX(-100%)

@keyframes progress-bar 
  0%
    width: 0
  100% 
    width: 100%

@keyframes arrowBouncing 
  0% 
    transform: translate(-50%, 0)
  25% 
    transform: translate(-50%, -10px)
  50% 
    transform: translate(-50%, 0)
  75% 
    transform: translate(-50%, 10px)
  100% 
    transform: translate(-50%, 0)