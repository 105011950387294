.background
  position: fixed
  left: 0
  top: 0
  width: 100%
  height: vh(100)
  overflow: hidden
  z-index: -1
  img, video
    @include fillImage
    position: absolute
    left: 0
    top: 0
    z-index: 1
  video
    transition: opacity 500ms ease-in-out
    opacity: 0
    z-index: 2
    &.loaded
      opacity: 1
    &.fading
      opacity: 0
      animation: fadeIn 1000ms ease-in-out
      animation-fill-mode: forwards
      z-index: 3
    &.active
      z-index: 3
