$intermediate-larger: "(min-aspect-ratio: 1310/1080) and (max-aspect-ratio: 1610/1080)"
$intermediate-smaller: "(min-aspect-ratio: 1/1) and (max-aspect-ratio: 1310/1080)"
$intermediate: "(min-aspect-ratio: 1/1) and (max-aspect-ratio: 1610/1080)"

.about-page
  position: relative
  width: 100vw
  height: vh(200)
  @media #{$tablet}
    height: auto
  @media #{$mobile}
    height: auto
  &__sticky
    position: sticky
    top: 0
    width: 100%
    height: vh(100)
    display: flex
    align-items: center
    justify-content: center 
    overflow: hidden
    @media #{$tablet}
      position: relative
      flex-direction: column
      padding: tpx(86) tpx(20)
      height: auto
      gap: tpx(44)
    @media #{$mobile}
      position: relative
      flex-direction: column
      padding: mpx(86) mpx(20)
      height: auto
      gap: mpx(44)
  &__text 
    --x: 0
    --y: 0
    --intermediate-x: 0
    position: absolute
    top: 50%
    right: 50%
    transform: translate(dpx(-24), -50%) translateX(calc(var(--x) * 1px))
    width: dpx(570)
    height: calc(100% - dpx(410))
    display: flex
    flex-direction: column
    justify-content: space-between
    @media #{$intermediate}
      height: calc(100% - dpx(645))
      transform: translate(50%, -50%) translateX(calc(var(--intermediate-x) * 1px))
      width: dpx(700)
    @media #{$intermediate-smaller}
      width: dpx(540)
    @media #{$tablet}
      transform: none
      position: relative
      top: 0
      left: 0
      width: tpx(450)
      transform: none 
      height: auto
      justify-content: flex-start
      gap: tpx(50)
    @media #{$mobile}
      transform: none
      position: relative
      top: 0
      left: 0
      width: 100% 
      transform: none 
      height: auto
      justify-content: flex-start
      gap: mpx(50)
    h1 
      font-size: dpx(80)
      font-weight: 700
      color: $dark
      @media #{$intermediate-smaller}
        font-size: dpx(64)
      @media #{$tablet}
        font-size: tpx(40)
        font-weight: 500
      @media #{$mobile}
        font-size: mpx(40)
        font-weight: 500
    p
      font-size: dpx(30)
      font-weight: 400
      @media #{$intermediate-smaller}
        font-size: dpx(24)
      @media #{$tablet}
        font-size: tpx(14)
      @media #{$mobile}
        font-size: mpx(14)

  &__video 
    --x: 0
    --y: 0
    --height: 0
    --width: 0
    --intermediate-height: 0
    --intermediate-width: 0
    --intermediate-x: 0
    position: absolute
    top: 50%
    left: 50%
    @include defaultImageRatio
    width: calc(var(--width) * 1px)
    transform: translate(-50%, -50%) translateX(calc(var(--x) * 1px))
    filter: grayscale(1)
    transition: filter 250ms $easeInOut
    max-width: dpx(1500)
    max-height: dpx(846)
    border-radius: dpx(10)
    overflow: hidden
    background-color: $dark
    cursor: pointer
    @media #{$intermediate}
      width: calc(var(--intermediate-width) * 1px)
      transform: translate(-50%, -50%) translateX(calc(var(--intermediate-x) * 1px))
    @media #{$tablet}
      position: relative
      top: 0
      left: 0
      transform: none
      max-width: unset 
      max-height: unset
      width: tpx(450)
      border-radius: tpx(10)
    @media #{$mobile}
      position: relative
      top: 0
      left: 0
      transform: none
      max-width: unset 
      max-height: unset
      width: 100% 
      border-radius: mpx(10)
    &:hover
      filter: grayscale(0)
    &--fullscreen 
      filter: grayscale(0)
      cursor: auto
    video 
      display: block
      width: 100%
      height: 100%
      object-fit: cover
  &__fullscreen-button 
    position: absolute
    visibility: hidden
    @media #{$tablet}
      visibility: visible
      width: tpx(24)
      height: tpx(24)
      padding: 0
      top: tpx(12)
      right: tpx(12)
    @media #{$mobile}
      visibility: visible
      width: mpx(48)
      height: mpx(48)
      padding: mpx(12)
      top: 0
      right: 0
    img 
      display: block
      object-fit: contain
      @media #{$tablet}
        width: tpx(24)
        height: tpx(24)
      @media #{$mobile}
        width: mpx(24)
        height: mpx(24) 