.progress-bar
  position: relative
  height: dpx(8)
  width: dpx(260)
  z-index: 2
  @media #{$tablet}
    height: tpx(8)
    width: tpx(178)
  @media #{$mobile}
    height: mpx(8)
    width: mpx(178)
  &__indicator 
    --duration: 4000ms
    position: absolute
    top: 0
    left: 0
    width: 0
    height: 100%
    background: $dark
    border-radius: dpx(4)
    @media #{$tablet}
      border-radius: tpx(4)
    @media #{$mobile}
      border-radius: mpx(4)
    &--animated
      animation: progress-bar var(--duration) linear 
      animation-fill-mode: forwards
  &__track
    position: absolute 
    top: 50%
    transform: translateY(-50%)
    width: 100%
    height: dpx(2)
    background: $dark
    @media #{$tablet}
      height: tpx(2)
    @media #{$mobile}
      height: mpx(2)
