@import './functions' 
@import './vars'
@import './mixin'
@import './animations'

*, *:before, *:after
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-decoration: none
  font-family: "Inter"
  font-weight: 400
  line-height: 120%
  color: $dark
  outline: none
  padding: 0
  margin: 0
  box-sizing: border-box
  -webkit-box-sizing: border-box

button, a
  outline: none
  border: none
  background: transparent
  cursor: pointer

body, html
  background-color: $gray2
  &::-webkit-scrollbar
    width: 0
  &::-webkit-scrollbar-thumb
    display: none
  &::-webkit-scrollbar-track
    display: none
  &::-webkit-scrollbar-button
    display: none

#root
  --vh: 1vh
  --vw: 1vw
  width: 100%
  position: relative
  z-index: 1

.application
  width: auto
  height: vh(100)
  max-width: 100%
  aspect-ratio: 16/9
  margin: 0 auto
  position: relative
  z-index: 1
  @media #{$tablet}
    width: 100%
    aspect-ratio: unset

@import './components/phoneSubmit'
@import './components/formWrapper'
@import './components/progressBar' 
@import './components/background'
@import './components/interface'
@import './components/newsModal'
@import './components/confirm'

@import './pages/propertyTypes'
@import './pages/services'
@import './pages/about'
@import ./pages/home
@import ./pages/wip
