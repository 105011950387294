@mixin noSelect
  user-select: none
  -webkit-user-select: none
  user-drag: none
  -webkit-user-drag: none

@mixin noTouch
  touch-action: none
  -webkit-touch-action: none

@mixin fillImage
  @include noSelect
  display: block
  width: 100%
  height: 100%
  object-fit: cover
  object-position: center

@mixin fitImage
  @include noSelect
  display: block
  width: 100%
  height: 100%
  object-fit: contain
  object-position: center

@mixin scrollBar
  &::-webkit-scrollbar
    display: block
    width: 6px
    border: none
  &::-webkit-scrollbar-thumb
    display: block
    width: 6px
    background-color: $dark
    border-radius: 3px
    cursor: pointer
  &::-webkit-scrollbar-track
    width: 2px
    background: linear-gradient(90deg, transparent 33%, $dark 33%, $dark 66%, transparent 66%)
  &::-webkit-scrollbar-button
    display: none

@mixin link
  transition: opacity 150ms ease-in-out
  cursor: url(../img/wip/cursor.svg) 40 40, pointer
  &:hover, &:active
    opacity: 0.4 !important

@mixin fadeTransition
  transition-property: opacity, transform
  transition-timing-function: cubic-bezier(.3,.1,.2,1)
  transition-duration: 500ms

@mixin fadeInRightAnimation
  @include fadeTransition
  transform: translateX(-50px)
  opacity: 0

@mixin fadeInUpAnimation
  @include fadeTransition
  transform: translateY(50px)
  opacity: 0

@mixin fadeInDownAnimation
  @include fadeTransition
  transform: translateY(-50px)
  opacity: 0

@mixin defaultImageRatio
  width: 100%
  height: auto 
  aspect-ratio: 16 / 9
