.confirm
  position: fixed
  left: 0
  top: 0
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  z-index: 100
  &-background
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: -1
    background-color: rgba($dark, 0.9)
  &-content
    position: relative
    width: dpx(570)
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    border-radius: dpx(10)
    background-color: $gray2
    border: 1px solid $dark
    z-index: 1
  &-title
    padding: dpx(40) dpx(40) 0 dpx(40)
    width: 100%
    h3
      font-size: dpx(40)
      font-weight: 500
  &-body
    padding: 0 dpx(40)
    width: 100%
    margin-top: dpx(30)
    p
      font-size: dpx(20)
  &-actions
    width: 100%
    display: flex
    align-items: flex-start
    justify-content: center
    margin-top: dpx(40)
    padding: 0 dpx(5) dpx(5) dpx(5)
    gap: dpx(5)
  &-button
    display: flex
    align-items: center
    justify-content: center
    text-align: center
    padding: dpx(18)
    flex: 1 1 auto
    font-size: dpx(20)
    font-weight: 500
    text-transform: uppercase
    border-radius: dpx(5)
    transition: opacity 150ms $easeInOut
    opacity: 1
    &:hover
      opacity: 0.5
    &:active
      opacity: 1
    &--cancel
      background-color: $gray5
    &--submit
      background-color: $dark
      color: $gray2
