$intermediate-larger: "(min-aspect-ratio: 1620 / 1080) and (max-aspect-ratio: 1920 / 1080)"
$intermediate-smaller: "(min-aspect-ratio: 1 / 1) and (max-aspect-ratio: 1620 / 1080)"
$intermediate: "(min-aspect-ratio: 1 / 1) and (max-aspect-ratio: 1920 / 1080)"

.services-page
  position: relative
  width: 100%
  height: vh(100)
  &__background-images 
    & > .background 
      height: calc(100vh + dpx(100))
      top: dpx(-50)
      transition-property: opacity, transform
      transition-duration: 500ms
      transition-timing-function: $easeInOut
      opacity: 0
      transform: translateY(dpx(50))
      @media #{$tablet}
        height: calc(100vh + tpx(100))
        top: tpx(-50)
        transform: translateY(tpx(50))
      @media #{$mobile}
        height: calc(100vh + mpx(100))
        top: mpx(-50)
        transform: translateY(mpx(50))
      &.background--visible 
        transform: translateY(0)
        opacity: 1
      &.background--behind 
        transform: translateY(dpx(-50))
        opacity: 1
        @media #{$tablet}
          transform: translateY(tpx(-50))
        @media #{$mobile}
          transform: translateY(mpx(-50))
      &::after 
        content: ''
        position: absolute
        z-index: 2
        left: 0
        top: 0
        width: 100%
        height: 100%
        background: $dark
        opacity: 0.6
  &__sections
    position: sticky
    top: 0
    width: 100%
    height: vh(100)
    overflow: hidden
    z-index: 1
    &-list
      width: 100%
  &__section 
    position: relative
    left: 0
    width: 100%
    height: vh(100)
    &:nth-child(1) > &-title
      transform: translate(dpx(25), dpx(-42))      
    &:nth-child(2) > &-title
      transform: translate(dpx(25), dpx(3)) 
    &:nth-child(3) > &-title
      transform: translate(dpx(25), dpx(48)) 
    &:nth-child(4) > &-title
      transform: translate(dpx(25), dpx(93)) 
      
    @media #{$intermediate-smaller}
      &:nth-child(1) > &-title
        transform: translate(0, dpx(-50)) 
      &:nth-child(2) > &-title
        transform: translate(0, dpx(-5)) 
      &:nth-child(3) > &-title
        transform: translate(0, dpx(40)) 
      &:nth-child(4) > &-title
        transform: translate(0, dpx(85)) 

    @media #{$tablet}
      &:nth-child(1) > &-title
        transform: translate(0, tpx(-35)) 
      &:nth-child(2) > &-title
        transform: translate(0, tpx(-5)) 
      &:nth-child(3) > &-title
        transform: translate(0, tpx(25)) 
      &:nth-child(4) > &-title
        transform: translate(0, tpx(55)) 

    @media #{$mobile}
      &:nth-child(1) > &-description
        transform: translate(0, mpx(-32)) 
      &:nth-child(2) > &-description
        transform: translate(0, mpx(-2)) 
      &:nth-child(3) > &-description
        transform: translate(0, mpx(28)) 
      &:nth-child(4) > &-description
        transform: translate(0, mpx(58)) 

    &-title 
      position: absolute
      right: 50%
      bottom: 50%
      width: dpx(620)
      font-size: dpx(80)
      font-weight: 700
      text-align: left
      color: $gray2
      @media #{$intermediate-larger}
        font-size: dpx(64)
        width: dpx(500)
      @media #{$intermediate-smaller}
        font-size: dpx(56)
        width: dpx(434)
        left: dpx(266)
        right: auto
      @media #{$tablet}
        left: tpx(211)
        bottom: 50%
        right: auto
        font-size: tpx(40)
        width: tpx(292)
      @media #{$mobile}
        top: mpx(86)
        right: auto 
        bottom: auto
        left: mpx(20)
        font-size: mpx(40)
        width: mpx(292)
        transform: none !important
        
    &-description
      position: absolute 
      top: 50%
      left: 50%
      transform: translate(dpx(24), dpx(60))
      width: dpx(570)
      font-size: dpx(30)
      font-weight: 400
      text-align: left
      color: $gray2 
      @media #{$intermediate-larger}
        width: dpx(500)
        font-size: dpx(27)
      @media #{$intermediate-smaller}
        width: dpx(400)
        font-size: dpx(24)
        transform: translate(dpx(100), dpx(60))
      @media #{$tablet}
        width: tpx(236)
        font-size: tpx(15)
        transform: translate(tpx(60), tpx(60))
      @media #{$mobile}
        width: mpx(236)
        font-size: mpx(15)
        transform: none
        left: mpx(119)

  &__navigation
    position: fixed
    left: calcLeftPd(dpx(55))
    top: 50%
    transform: translateY(-50%)
    z-index: 2
    @media (max-aspect-ratio: 16 / 9)
      left: dpx(55)
    @media #{$tablet}
      left: tpx(55)
      transform: translateY(-50%)
    @media #{$mobile}
      left: mpx(20)
      transform: translateY(-50%)
    &-dots 
      display: flex
      flex-direction: column
      gap: dpx(30)
      @media #{$tablet}
        gap: tpx(20)
      @media #{$mobile}
        gap: mpx(20)
      button 
        width: dpx(15)
        height: dpx(15)
        background-color: transparent 
        border: dpx(2) solid $gray2
        border-radius: 50%
        transition: background-color 250ms $easeInOut
        @media #{$tablet}
          width: tpx(10)
          height: tpx(10)
          border: tpx(2) solid $gray2
        @media #{$mobile}
          width: mpx(10)
          height: mpx(10)
          border: mpx(2) solid $gray2
        &.active
          background-color: $gray2
    &-indicator 
      display: flex
      align-items: center
      height: dpx(24)
      gap: dpx(30)
      position: absolute
      left: dpx(45)
      top: dpx(-6)
      transition: transform 250ms $easeInOut
      @media #{$tablet}
        height: tpx(18)
        gap: tpx(10)
        left: tpx(20)
        top: tpx(-4)
      @media #{$mobile}
        height: mpx(18)
        gap: mpx(10)
        left: mpx(20)
        top: mpx(-4)
      &--1 
        transform: translateY(dpx(0))
      &--2 
        transform: translateY(dpx(45))
      &--3
        transform: translateY(dpx(90))
      &--4 
        transform: translateY(dpx(135))
      @media #{$tablet}
        &--1 
          transform: translateY(tpx(0))
        &--2 
          transform: translateY(tpx(30))
        &--3
          transform: translateY(tpx(60))
        &--4 
          transform: translateY(tpx(90))
      @media #{$mobile}
        &--1 
          transform: translateY(mpx(0))
        &--2 
          transform: translateY(mpx(30))
        &--3
          transform: translateY(mpx(60))
        &--4 
          transform: translateY(mpx(90))
    &-hr 
      height: dpx(2)
      width: dpx(172)
      background: $gray2
      @media #{$intermediate-smaller}
        width: dpx(70)
      @media #{$tablet}
        width: tpx(69)
        height: tpx(2)
      @media #{$mobile}
        width: mpx(69)
        height: mpx(2)
    &-number 
      font-size: dpx(20)
      font-weight: 500
      line-height: dpx(24)
      text-align: left
      color: $gray2
      @media #{$tablet}
        font-size: tpx(15)
        line-height: tpx(18)
      @media #{$mobile}
        font-size: mpx(15)
        line-height: mpx(18)
