$intermediate-larger: "(min-aspect-ratio: 1300 / 1080) and (max-aspect-ratio: 1580 / 1080)"
$intermediate-smaller: "(min-aspect-ratio: 1 / 1) and (max-aspect-ratio: 1300 / 1080)"
$intermediate: "(min-aspect-ratio: 1 / 1) and (max-aspect-ratio: 1580 / 1080)"

.about-section
  width: 100%
  height: 300vh
  z-index: 2
  @media #{$tablet}
    height: 200vh
  &__sticky
    position: sticky
    top: 0
    width: 100%
    height: vh(100)
    display: flex
    align-items: center
    justify-content: center
  &__text
    padding-top: dpx(16)
    text-align: center
    font-size: dpx(120)
    font-weight: 500
    @media #{$intermediate-larger}
      font-size: dpx(100)
    @media #{$intermediate-smaller}
      font-size: dpx(80)
    @media #{$tablet}
      width: tpx(450)
      padding-top: tpx(8)
      font-size: tpx(34)
    @media #{$mobile}
      width: 100%
      padding-top: mpx(8)
      font-size: mpx(34)
    .br 
      &--desktop 
        display: block
      &--mobile 
        display: none
      @media #{$mobile}
        &--desktop 
          display: none
        &--mobile 
          display: block  
    &-space
      display: inline-block
      width: dpx(280)
      margin: 0 dpx(56)
      @media #{$intermediate}
        width: dpx(240)
        margin: 0 dpx(28)
      @media #{$tablet}
        width: tpx(105)
        margin: 0 tpx(20)
      @media #{$mobile}
        width: mpx(105)
        margin: 0 mpx(20)
  &__video
    --x: 0
    --y: 0
    --height: 0
    --width: 0

    --intermediate-width: 0
    --intermediate-x: 0
    --intermediate-y: 0

    --tablet-width: 0
    --tablet-x: 0
    --tablet-y: 0

    --mobile-width: 0
    --mobile-x: 0
    --mobile-y: 0
    position: absolute
    left: 50%
    top: 50%
    @include defaultImageRatio
    width: calc(var(--width) * 1px)
    transform: translate(-50%, -50%) translate(calc(var(--x) * 1px), calc(var(--y) * 1px))
    border-radius: dpx(10)
    filter: grayscale(1)
    transition: filter 250ms $easeInOut
    background-color: $dark
    overflow: hidden
    cursor: pointer
    @media #{$intermediate}
      width: calc(var(--intermediate-width) * 1px)
      transform: translate(-50%, -50%) translate(calc(var(--intermediate-x) * 1px), calc(var(--intermediate-y) * 1px))
    @media #{$tablet}
      width: calc(var(--tablet-width) * 1px)
      transform: translate(-50%, -50%) translate(calc(var(--tablet-x) * 1px), calc(var(--tablet-y) * 1px))
      border-radius: tpx(10)
      filter: none
    @media #{$mobile}
      width: calc(var(--mobile-width) * 1px)
      transform: translate(-50%, -50%) translate(calc(var(--mobile-x) * 1px), calc(var(--mobile-y) * 1px))
      border-radius: mpx(10)
      filter: none
    &:hover
      filter: grayscale(0)
      @media #{$mobile}
        filter: none
    video
      display: block
      object-fit: cover
      width: 100% 
      height: 100%
    &--fullscreen 
      filter: grayscale(0)
      cursor: unset
  &__fullscreen-button
    position: absolute
    opacity: 0
    pointer-events: none
    transition: opacity 250ms $easeInOut
    @media #{$tablet}
      width: tpx(24)
      height: tpx(24)
      padding: 0
      top: tpx(12)
      right: tpx(12)
    @media #{$mobile}
      width: mpx(48)
      height: mpx(48)
      padding: mpx(12)
      top: 0
      right: 0
    img 
      display: block
      @media #{$tablet}
        width: tpx(24)
        height: tpx(24)
      @media #{$mobile}
        width: mpx(24)
        height: mpx(24)  
    &--visible 
      @media #{$tablet}
        pointer-events: unset
        opacity: 1
      @media #{$mobile}
        pointer-events: unset
        opacity: 1