$intermediate-larger: "(min-aspect-ratio: 1300 / 1080) and (max-aspect-ratio: 1580 / 1080)"
$intermediate-smaller: "(min-aspect-ratio: 1 / 1) and (max-aspect-ratio: 1300 / 1080)"
$intermediate: "(min-aspect-ratio: 1 / 1) and (max-aspect-ratio: 1580 / 1080)"

.services-section
  --index: 0
  --count: 5
  width: 100%
  height: calc(var(--count) * 200vh)
  z-index: 1
  @media #{$tablet}
    overflow: hidden
    height: unset
  &__anchor
    position: relative
    left: 0
    top: vh(200)
    @media #{$tablet}
      top: 0
  &__sticky
    position: sticky
    top: 0
    width: calc(vh(100) * (16 / 9))
    max-width: 100%
    height: vh(100)
    margin: 0 auto
    display: flex
    align-items: flex-start
    justify-content: flex-start
    padding: 0 dpx(55)
    overflow: hidden
    @media #{$tablet}
      overflow: unset
      width: tpx(450)
      height: auto
      padding: 0
      flex-direction: column
      justify-content: flex-start
    @media #{$mobile}
      width: 100%
      min-height: 100vh
      padding: mpx(75) mpx(20) mpx(50) mpx(20)
  &__title
    overflow: hidden
    position: sticky
    left: 0
    top: dpx(150)
    z-index: 2
    @media #{$tablet}
      position: relative
      top: 0
      left: 0
      overflow: visible
      width: 100%
    @media #{$mobile}
      padding-bottom: 0
    h2
      --x: -100
      font-size: dpx(80)
      position: relative
      transform: translateX(calc(var(--x) * 1%))
      @media #{$intermediate-larger}
        font-size: dpx(72)
      @media #{$intermediate-smaller}
        font-size: dpx(64)
      @media #{$tablet}
        transform: none
        font-size: tpx(40)
        font-weight: 500
      @media #{$mobile}
        transform: none
        font-size: mpx(40)
        font-weight: 500
      svg
        position: absolute
        right: 0
        bottom: 0
        width: dpx(80)
        height: dpx(80)
        transform: rotate(270deg)
        @media #{$intermediate-larger}
          width: dpx(72)
          height: dpx(72)
        @media #{$intermediate-smaller}
          width: dpx(64)
          height: dpx(64)
        @media #{$tablet}
          display: none
        @media #{$mobile}
          display: none
  &__list
    width: 100%
    height: 100%
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    z-index: 1
    touch-action: auto !important
    @media #{$tablet}
      padding: tpx(50) 0 0 0
      gap: tpx(50)
    @media #{$mobile}
      padding: mpx(83) mpx(10) 0
      gap: mpx(88)
    &--desktop
      display: flex
      margin-left: auto
      position: absolute
      left: 0
      top: 0
      padding: inherit
      @media #{$tablet}
        display: none
      .swiper-slide
        width: 100%
        height: vh(100)
        display: flex
        justify-content: flex-end
        align-items: center
    &--mobile
      display: none
      width: 100%
      @media #{$tablet}
        display: flex
    .swiper
      width: 100%
      overflow: unset
  &__item
    position: relative
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: center
    min-height: vh(100)
    width: dpx(881)
    gap: dpx(90)
    pointer-events: auto
    @media #{$intermediate-larger}
      width: dpx(750)
      gap: dpx(81)
    @media #{$intermediate-smaller}
      width: dpx(600)
      gap: dpx(72)
    @media #{$tablet}
      min-height: unset
      width: 100%
      gap: tpx(15)
    @media #{$mobile}
      min-height: unset
      width: 100%
      gap: mpx(20)
    &__preview
      @include fillImage
      @include defaultImageRatio
      border-radius: dpx(10)
      filter: grayscale(1)
      transition: filter 250ms $easeInOut
      background-color: $gray5
      @media #{$tablet}
        border-radius: tpx(10)
        filter: grayscale(0)
      @media #{$mobile}
        border-radius: mpx(10)
        filter: grayscale(0)
      @at-root .services-section__item:hover &
        filter: grayscale(0)
    &__title
      position: relative
      h3
        position: relative
        font-size: dpx(160)
        font-weight: 500
        white-space: nowrap
        transition-property: opacity, color
        transition-duration: 250ms
        transition-timing-function: $easeInOut
        z-index: 1
        @media #{$intermediate-larger}
          font-size: dpx(144)
        @media #{$intermediate-smaller}
          font-size: dpx(128)
        @media #{$tablet}
          font-size: tpx(60)
        @media #{$mobile}
          font-size: mpx(60)
        &.fill
          color: $dark
          z-index: 2
          @media (any-pointer: coarse)
            color: $dark !important
          @at-root .services-section__item:hover &
            color: $gray2
        &.outline
          position: absolute
          left: 0
          top: 0
          color: $gray2
          -webkit-text-stroke: 2px $dark
          text-stroke: 2px $dark
