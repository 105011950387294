.news-modal
  position: relative
  width: 100%
  height: vh(100)
  background: $gray2
  display: flex
  align-items: center
  justify-content: center
  @media #{$tablet}
    height: auto
    padding: tpx(80) tpx(55) tpx(70)
  @media #{$mobile}
    height: auto
    padding: mpx(55) mpx(20) mpx(68) mpx(20)
  .news-block
    position: relative
    max-width: dpx(1190)
    width: calc(100% - dpx(110))
    padding: dpx(20) 0 dpx(50)
    border-top: dpx(2) solid $dark
    border-bottom: dpx(6) solid $dark
    @media #{$tablet}
      width: 100%
      height: 100%
      max-width: unset
      padding: tpx(10) 0 tpx(20) 0
      border-top: tpx(2) solid $dark
      border-bottom: none
    @media #{$mobile}
      width: 100%
      height: 100%
      max-width: unset
      padding: mpx(10) 0 0 0
      border-top: mpx(2) solid $dark
      border-bottom: none
    &__header
      display: flex 
      justify-content: space-between
      width: 100%
      &-date 
        display: flex
        align-items: center
        justify-content: flex-start
        gap: dpx(20)
        font-size: dpx(20)
        font-weight: 600
        color: $dark
        @media #{$tablet}
          gap: tpx(10)
          font-size: tpx(15)
          font-weight: 500
        @media #{$mobile}
          gap: mpx(10)
          font-size: mpx(15)
          font-weight: 500
        &::before
          content: ''
          position: relative
          width: dpx(14)
          height: dpx(14)
          border-radius: 50%
          background-color: $dark
          @media #{$tablet}
            width: tpx(12)
            height: tpx(12)
          @media #{$mobile}
            width: mpx(12)
            height: mpx(12)
      &-close
        font-size: dpx(20)
        font-weight: 500
        text-transform: uppercase
        transition: opacity 150ms $easeInOut
        &:hover
          opacity: 0.5
        &:active
          opacity: 1
    &__content
      position: relative
      margin: dpx(50) 0 0 0
      display: flex
      align-items: flex-start 
      justify-content: space-between
      gap: dpx(50)
      max-height: dpx(626)
      overflow: auto
      @include scrollBar
      @media #{$tablet}
        flex-direction: column
        gap: tpx(12)
        max-height: unset
        height: 100%
        width: tpx(450)
        justify-content: flex-start
        margin: tpx(10) auto
      @media #{$mobile}
        margin: mpx(10) 0 0 0
        gap: mpx(12)
        max-height: unset
        height: auto
        width: 100%
        justify-content: flex-start
        overflow: unset
      &-preview 
        flex: 1 1 dpx(570)
        position: sticky
        top: 0
        @include defaultImageRatio
        @media #{$tablet}
          width: 100%
          flex: none
          position: relative
        @media #{$mobile}
          width: 100%
          flex: none
          position: relative
        img 
          border-radius: dpx(10)
          background-color: $gray5
          width: 100%
          @include fillImage
          @media #{$tablet}
            border-radius: tpx(10)
          @media #{$mobile}
            border-radius: mpx(10)
      &-text 
        flex: 1 1 dpx(570)
        @media #{$tablet}
          flex: none
          width: 100%
          height: auto
        @media #{$mobile}
          width: 100%
          display: flex
          flex-direction: column
          align-items: flex-start
          justify-content: flex-start
          height: auto
        h1 
          font-size: dpx(40)
          font-weight: 500
          color: $dark
          @media #{$tablet}
            font-size: tpx(20)
          @media #{$mobile}
            font-size: mpx(20)
            flex: none
        p
          margin-top: dpx(50)
          max-width: dpx(570)
          font-size: dpx(20)
          white-space: pre-line
          height: auto
          @media #{$tablet}
            width: 100%
            margin-top: tpx(20)
            max-width: unset 
            padding-bottom: tpx(20)
            font-size: tpx(14)
          @media #{$mobile}
            margin-top: mpx(20)
            padding-bottom: 0
            flex-grow: 1
            font-size: mpx(14)
